import { calculateShapingsTotal } from './calculateShapingsTotal';
import { getItemById } from './utils/getItemById';
import { calculateSquareMeterPrice } from './utils/calculateSquareMeterPrice';
import { createItemsMap } from './utils/createItemsMap';

/**
 * Calculate the total costs for materials in an order.
 *
 * @function
 * @param {Array} fields - Array of material fields in the order
 * @param {Array} rates - Array of VAT rates
 * @param {Array} materials - Array of available materials
 * @param {Array} shapings - Array of shapings data
 * @param {Boolean} materialProvidedByCustomer - Whether materials are provided by the customer
 * @param {Number} customerCoefficient - Customer coefficient value
 * @param {Object} form - The form object of the Form instance
 *
 * @returns {Object} - Object containing base and option totals
 */
export const calculateMaterialsTotal = (
  fields,
  rates,
  materials,
  shapings,
  materialProvidedByCustomer,
  customerCoefficient,
  form
) => {
  let materialsVATAmount = 0;
  let shapingCommissionAmount = 0;
  let shapingsVATAmount = 0;
  let materialsCommissionAmount = 0;

  const base = {
    shapingsTotalWithoutVAT: 0,
    materialsCustomerCoefficientTotal: 0,
    shapingCoefficientTotal: 0,
    materialsPriceWithCustomerCoefficient: 0,
    materialsPriceWithoutCustomerCoefficient: 0,
    shapingsWithoutCustomerCoefficient: 0,
    materialsAndShapingsCommission: 0,
    materialsAndShapingsVATAmount: 0,
    materialsPriceWithCommission: 0
  };
  const option = {
    ...base
  };

  const baseVatSums = {};
  const optionVatSums = {};

  if (fields || fields.length > 0) {
    const materialsMap = createItemsMap(materials);

    fields.forEach((currentVolume, i) => {
      if (currentVolume) {
        const target = currentVolume.is_option ? option : base;
        if (
          currentVolume?.VAT_rate &&
          currentVolume?.commission &&
          currentVolume?.thickness
        ) {
          const item = materialsMap[currentVolume.materials_catalog];

          if (item) {
            const thicknessItem = item.slices_management.find(
              (thickness) => thickness._id === currentVolume.thickness
            );
            const squareMeterPrice = calculateSquareMeterPrice(thicknessItem);

            const wishedDimensions =
              ((currentVolume.wished_length / 1000) *
                currentVolume.wished_width) /
              1000;

            const quantity = currentVolume.quantity || 0;

            const itemRawPrice = squareMeterPrice * wishedDimensions * quantity;

            const itemCoefficientAmount =
              itemRawPrice * (customerCoefficient / 100);

            const itemPriceWithCustomerCoefficient =
              itemRawPrice + itemCoefficientAmount;

            const commission = Number(currentVolume.commission);
            const itemCommissionAmount =
              itemPriceWithCustomerCoefficient * (commission / 100);

            const itemCoefficientAndCommissionAmount =
              itemCoefficientAmount + itemCommissionAmount;

            const itemPrice = itemRawPrice + itemCoefficientAndCommissionAmount;

            const vatRate = getItemById(rates, currentVolume.VAT_rate);
            const vat = vatRate.percentage;
            const itemVATAmount = itemPrice * (vat / 100);

            if (
              !Number.isNaN(itemVATAmount) &&
              itemVATAmount !== 0 &&
              vat !== 0
            ) {
              if (target === base) {
                if (!Object.prototype.hasOwnProperty.call(baseVatSums, vat)) {
                  baseVatSums[vat] = 0;
                }

                baseVatSums[vat] += itemVATAmount;
              }
              if (target === option) {
                if (!Object.prototype.hasOwnProperty.call(optionVatSums, vat)) {
                  optionVatSums[vat] = 0;
                }

                optionVatSums[vat] += itemVATAmount;
              }
            }

            materialsCommissionAmount += itemCoefficientAndCommissionAmount;
            materialsVATAmount += itemVATAmount;

            form.setFields([
              {
                name: ['materials', i, 'unprocessed_value'],
                value: itemRawPrice
              },
              {
                name: ['materials', i, 'customer_coefficient_value'],
                value: Number(itemPriceWithCustomerCoefficient.toFixed(2))
              },
              {
                name: ['materials', i, 'facial_value'],
                value: Number(itemPrice.toFixed(2))
              },
              {
                name: ['materials', i, 'all_included_value'],
                value: Number(itemPrice + itemVATAmount).toFixed(2)
              },
              {
                name: ['materials', i, 'commission'],
                value: commission
              }
            ]);

            target.materialsPriceWithoutCustomerCoefficient += itemRawPrice;
            target.materialsPriceWithCustomerCoefficient +=
              itemPriceWithCustomerCoefficient;
            target.materialsPriceWithCommission += itemPrice;
            target.materialsCustomerCoefficientTotal +=
              target.materialsPriceWithCustomerCoefficient -
              target.materialsPriceWithoutCustomerCoefficient;
            target.materialsAndShapingsCommission += materialsCommissionAmount;
            target.materialsAndShapingsVATAmount += materialsVATAmount;
          }
        }
        if (currentVolume.shapings && currentVolume.shapings[0] !== undefined) {
          const shapingTotals = calculateShapingsTotal(
            currentVolume.shapings,
            shapings,
            materialProvidedByCustomer,
            customerCoefficient,
            rates,
            form,
            i
          );

          shapingCommissionAmount += shapingTotals.shapingCommissionAmount;
          shapingsVATAmount += shapingTotals.shapingVATAmount;

          target.shapingCoefficientTotal +=
            shapingTotals.customerCoefficientTotal;
          target.shapingsWithoutCustomerCoefficient +=
            shapingTotals.priceWithoutCustomerCoefficient;
          target.shapingsTotalWithoutVAT +=
            shapingTotals.shapingsTotalWithoutVAT;
          target.materialsAndShapingsCommission += shapingCommissionAmount;
          target.materialsAndShapingsVATAmount += shapingsVATAmount;
        }
      }
    });
  }

  return {
    base,
    option,
    baseVatSums,
    optionVatSums
  };
};

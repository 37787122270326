import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { OrdersList } from './OrderList';
import { PageHeaderCustom } from '../../components';
import { HeaderFilter } from '../../components/HeaderFilter/HeaderFilter';
import { KanbanView } from '../../components/KanbanView/KanbanView';
import { OrderHeader } from './OrderHeader';
import { KanbanCardOrder } from './KanbanCardOrder';

/**
 * Represents the Kanban view of orders.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isKanbanView - Indicates whether the current view is kanban view.
 * @param {boolean} props.refresh - Indicates whether the component should refresh.
 * @param {function} props.setRefresh - Function to set the refresh status.
 * @param {function} props.setIsKanbanView - Function to set the kanban view mode.
 * @returns {JSX.Element} A JSX element representing the OrdersKanbanView component.
 */
const OrdersKanbanView = ({
  refresh,
  setRefresh,
  isKanbanView,
  setIsKanbanView
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [kanbanViewHeaders, setKanbanViewHeaders] = useState([]);
  const [archiveFilter, setArchiveFilter] = useState(false);
  const [customerFilter, setCustomerFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const extraQuery = `${customerFilter ? `customer=${customerFilter}` : ''}${
    statusFilter ? `${customerFilter ? `&` : ''}${statusFilter}` : ''
  }`;

  const getKanbanViewHeaders = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'kanbanview-actions'
      });
      data.sort((a, b) => a.number_place - b.number_place);
      const archivedColumn = {
        color: '#ababab',
        display: archiveFilter,
        title: t('orders.statuses.ARCHIVED'),
        _id: 'ARCHIVED'
      };
      const beginColumn = {
        color: '#c1ccff',
        display: true,
        title: t('orders.statuses.ORDER_SIGNED'),
        _id: 'ORDER_SIGNED'
      };
      data.push(archivedColumn);
      data.unshift(beginColumn);
      setKanbanViewHeaders(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getKanbanViewHeaders();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom title={t('orders.title')} />
      <HeaderFilter
        archiveFilter={archiveFilter}
        setArchiveFilter={setArchiveFilter}
        resourceName="orders"
        extraQuery={extraQuery}
        populate="customer,project.project_type,kanban_status"
        setRefresh={setRefresh}
        refresh={refresh}
        withArchiveButton
        withCreateButton={false}
        extraFilters={
          <OrderHeader
            isKanbanView={isKanbanView}
            setCustomerFilter={setCustomerFilter}
            setStatusFilter={setStatusFilter}
            setIsKanbanView={setIsKanbanView}
          />
        }
      >
        <KanbanView
          resourceName="orders"
          refresh={refresh}
          setRefresh={setRefresh}
          columnHeaders={kanbanViewHeaders}
          defaultStatus="ORDER_SIGNED"
        >
          <KanbanCardOrder editAction={false} />
        </KanbanView>
      </HeaderFilter>
    </>
  );
};

OrdersKanbanView.propTypes = {
  isKanbanView: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
  setIsKanbanView: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};

export const Orders = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);
  const [isKanbanView, setIsKanbanView] = useState(false);
  const [statusesColors, setStatusesColors] = useState([]);

  const getStatusesColors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'quotation-statuses-colors'
      });
      setStatusesColors(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getStatusesColors();
  }, [refresh]);

  return !isKanbanView ? (
    <OrdersList
      isKanbanView={isKanbanView}
      setIsKanbanView={setIsKanbanView}
      statusesColors={statusesColors}
    />
  ) : (
    <OrdersKanbanView
      isKanbanView={isKanbanView}
      setIsKanbanView={setIsKanbanView}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Input, Row, Space, Switch } from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { ImportFile } from '../ImportFile/ImportFile';

const { Search } = Input;

export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraFilters,
  extraButtons,
  extraMenu,
  exportUrl,
  withArchiveButton,
  withCreateButton,
  withUploadButton,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  withImportButton
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [archiveFilter, setArchiveFilter] = useState(false);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = {
    items: [
      ...extraMenu,
      ...(withImportButton
        ? [
            {
              key: 'import',
              label: (
                <ImportFile headers={headers} resourceName={resourceName} />
              )
            }
          ]
        : []),
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceModelName || resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                />
              )
            }
          ]
        : [])
    ]
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`${tradKey || resourceName}.title`)}
        extra={extraHeader}
      />
      <ContentCustom>
        <Row
          justify="space-between"
          gutter={[8, 16]}
          style={{ flexWrap: 'nowrap' }}
        >
          <Col lg={3} xl={3} xxl={4}>
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
            />
          </Col>
          <Col lg={15} xl={14} xxl={11}>
            {extraFilters}
          </Col>

          <Col lg={6} xl={6} xxl={5}>
            <Row justify="end" align="middle" gutter={[24]}>
              {withArchiveButton && (
                <Col>
                  <Space>
                    <Switch
                      onChange={() => setArchiveFilter(!archiveFilter)}
                      checkedChildren={t('buttons.archived')}
                      unCheckedChildren={t('buttons.not_archived')}
                    />
                  </Space>
                </Col>
              )}
              {!withCreateButton && headers && (
                <Col>
                  <Dropdown menu={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                </Col>
              )}
              {withCreateButton && (
                <Col>
                  <Row align="middle">
                    {extraButtons}
                    <Link to={withCreateButton?.path || `${pathname}/create`}>
                      <Button type="add">
                        {withCreateButton?.buttonText ||
                          `${t('buttons.create')}`}
                        &nbsp;
                        {withCreateButton?.buttonIcon || <PlusOutlined />}
                      </Button>
                    </Link>
                    {withUploadButton && (
                      <Dropdown menu={menu}>
                        <Button type="link">
                          <MenuOutlined
                            style={{ fontSize: 16, color: 'var(--textColor)' }}
                          />
                        </Button>
                      </Dropdown>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          archiveFilter={archiveFilter}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraFilters: PropTypes.element,
  extraButtons: PropTypes.element,
  extraMenu: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.element.isRequired
    })
  ),
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  withArchiveButton: PropTypes.bool,
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  withImportButton: PropTypes.bool
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraFilters: null,
  extraButtons: null,
  extraMenu: [],
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withArchiveButton: false,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  withImportButton: false
};

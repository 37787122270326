import { useTranslation } from 'react-i18next';

/**
 * useMaterialsColumns is a custom hook that returns an array of column configurations for displaying material-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useMaterialsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('quotations.table.volume'),
      key: 'is_option',
      dataIndex: 'is_option',
      render: (boolean) =>
        boolean ? t('quotations.table.option') : t('quotations.table.base')
    },
    {
      title: t('quotations.table.piece_type'),
      key: 'piece_type',
      dataIndex: 'piece_type'
    },
    {
      title: t('quotations.table.materials_catalog'),
      key: 'materials_catalog',
      dataIndex: 'materials_catalog',
      render: (material) => material && material?.denomination
    },
    {
      title: t('quotations.table.slice'),
      key: 'thickness',
      dataIndex: 'thickness',
      render: (thickness) =>
        thickness &&
        `${thickness.length}x${thickness.width || thickness.height}, ép.${
          thickness.thickness
        }`
    },
    {
      title: t('quotations.table.dimensions'),
      render: (record) =>
        record &&
        record.wished_width &&
        record.wished_length &&
        `${record.wished_width}x${record.wished_length}`
    },
    {
      title: t('quotations.table.quantity'),
      key: 'quantity',
      dataIndex: 'quantity'
    },
    {
      title: t('quotations.table.surface'),
      render: (record) =>
        record &&
        record.wished_length &&
        record.wished_width &&
        (record.wished_length / 1000) * (record.wished_width / 1000)
    },
    {
      title: t('quotations.table.unprocessed_value'),
      render: ({ thickness, wished_length, wished_width }) => {
        if (thickness && wished_length && wished_width) {
          const priceItem = thickness?.prices_management?.sort(
            (a, b) => a.application_date - b.application_date
          )[0];
          const unprocessed_value =
            (priceItem?.sell_price || 0) *
            ((wished_length / 1000) * (wished_width / 1000));
          return unprocessed_value && `${unprocessed_value}€`;
        }
        return false;
      }
    },
    {
      title: t('quotations.table.customer_coefficient_value'),
      key: 'customer_coefficient_value',
      dataIndex: 'customer_coefficient_value',
      render: (value) => value && `${value}€`
    },
    {
      title: t('quotations.table.commission'),
      key: 'commission',
      dataIndex: 'commission',
      render: (rate) => rate && `${rate}%`
    },
    {
      title: t('quotations.table.facial_value'),
      key: 'facial_value',
      dataIndex: 'facial_value',
      render: (value) => value && `${value}€`
    },
    {
      title: t('quotations.table.VAT_rate'),
      key: 'VAT_rate',
      dataIndex: 'VAT_rate',
      render: (rate) => rate && `${rate.percentage}%`
    },
    {
      title: t('quotations.table.all_included_value'),
      key: 'all_included_value',
      dataIndex: 'all_included_value',
      render: (value) => value && `${value.toFixed(2)}€`
    }
  ];
};

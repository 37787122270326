import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
/**
 * Custom hook for defining table columns for managing price information.
 *
 * @hook
 *
 * @param {function} handleEdit - Function to edit Row.
 * @param {function} handleRemove - Function to remove Row.
 * @param {string} purpose - Purpose of the array.
 * @returns {Array} An array of column objects.
 */
export const useColumns = (handleEdit, handleRemove, purpose) => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.form.details_type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => type || '0€'
    },
    {
      title: t('invoices.form.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => quantity || '0€'
    },
    {
      title: t('invoices.form.denomination'),
      dataIndex: 'denomination',
      key: 'denomination',
      render: (denomination) => denomination || '0€'
    },
    {
      title: t('invoices.form.unit_price'),
      dataIndex: 'unit_price',
      key: 'unit_price',
      render: (unit_price) =>
        (unit_price && `${Number(unit_price).toFixed(2)}€`) || '0€'
    },
    {
      title: t('invoices.form.VAT_excluded_price'),
      dataIndex: 'VAT_excluded_price',
      key: 'VAT_excluded_price',
      render: (VAT_excluded_price) =>
        (VAT_excluded_price && `${Number(VAT_excluded_price).toFixed(2)}€`) ||
        '0€'
    },
    {
      title: t('invoices.form.VAT_price'),
      dataIndex: 'VAT_price',
      key: 'VAT_price',
      render: (VAT_price) =>
        (VAT_price && `${Number(VAT_price).toFixed(2)}€`) || '0€'
    },
    {
      title: t('invoices.form.all_included_value'),
      dataIndex: 'all_included_value',
      key: 'all_included_value',
      render: (all_included_value) =>
        all_included_value ? `${Number(all_included_value).toFixed(2)}€` : '0€'
    },
    ...(purpose === 'form'
      ? [
          {
            render: (record) =>
              [
                t('invoices.form.extra_addition'),
                t('invoices.form.discount')
              ].includes(record.type) && (
                <>
                  <Button onClick={() => handleEdit(record)}>
                    <EditOutlined />
                  </Button>
                  <Button onClick={() => handleRemove(record)}>
                    <DeleteOutlined />
                  </Button>
                </>
              )
          }
        ]
      : [])
  ];
};

import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating content data for displaying accessory information in a list view.
 *
 * @hook
 *
 * @param {Object} props - The props object.
 * @param {string} props.nomenclature - The nomenclature of the accessory.
 * @param {Object} props.category - The category of the accessory.
 * @param {Object} props.supplier - The supplier of the accessory.
 * @param {number} props.price - The price of the accessory.
 * @returns {Array} An array of content objects.
 */
export const useListContent = ({ nomenclature, category, supplier, price }) => {
  const { t } = useTranslation();

  return [
    {
      label: 'accessories.show.nomenclature',
      content: nomenclature || t('errors.form.not_specified')
    },
    {
      label: 'accessories.show.category',
      content: (category && category.title) || t('errors.form.not_specified')
    },
    {
      label: 'accessories.show.supplier',
      content:
        (supplier && supplier.denomination) || t('errors.form.not_specified')
    },
    {
      label: 'accessories.show.price',
      content: price || t('errors.form.not_specified')
    }
  ];
};

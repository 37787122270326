import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { customerTypes } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

/**
 * Custom hook to generate form fields related to customers.
 *
 * This hook structures customer-related form fields in a format suitable for rendering within a form component.
 * It uses predefined customer type enumerations from a backend endpoint to generate a select input for the customer type.
 * It also provides the status of whether the fields are still being populated (loading).
 *
 * @hook
 *
 * @returns {Object} Object containing:
 * - `basicFields`: An array of form field configurations, where each configuration is an object
 *   that may contain properties such as `name`, `rules`, `label`, and `input` representing
 *   field name, validation rules, label, and input component respectively.
 * - `isFieldsLoading`: A boolean indicating if the fields (specifically enums) are still being fetched.
 */
const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);

  const basicFields = [
    {
      name: ['type'],
      input: (
        <Select>
          {(enums.type || []).map((item) => (
            <Option key={item._id} value={item}>
              <Tag color={customerTypes[item]}>
                {t(`customers.form.${item}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      label: 'phone_number',
      name: ['phone_number'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'email',
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }]
    },

    {
      label: 'commission',
      name: ['commission'],
      rules: [{ required: true }],
      input: <Input type="number" />
    },
    {
      label: 'street',
      name: ['billing_address', 'street'],
      rules: [{ required: true }]
    },
    {
      label: 'address_details',
      name: ['billing_address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['billing_address', 'postal_code'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      label: 'city',
      name: ['billing_address', 'city'],
      input: <Input />,
      rules: [{ required: true }]
    },
    {
      label: 'business_provider',
      name: ['business_provider'],
      rules: [{ required: true }]
    },
    {
      label: 'coefficient',
      name: ['coefficient'],
      input: <InputNumber min={0} />
    },
    {
      label: 'remark',
      name: ['remark'],
      input: <TextArea />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getEnums();
      setIsFieldsLoading(false);
    })();
  }, []);

  return {
    basicFields,
    isFieldsLoading
  };
};

export default useFields;

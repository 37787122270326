import { useTranslation } from 'react-i18next';

/**
 * useInvoiceColumns is a custom hook that returns an array of column configurations for displaying invoice-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useAdvanceColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.show.body_infos.label'),
      dataIndex: 'label'
    },
    {
      title: t('invoices.show.totals_infos.totalTTC'),
      dataIndex: 'total',
      key: 'total',
      render: (total) => total && `${total.toFixed(2)}€`
    }
  ];
};

import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useCatalogMaterialContext } from '../../../contexts/CatalogMaterialContext';
import { ActionSliceManagement } from './show/SlicesManagement/ActionSliceManagement';

/**
 * Custom hook to generate column configurations for displaying catalog material information.
 *
 * @hook
 *
 * @param {string} materialId - The ID of the current material.
 * @param {boolean} forceRefresh - Flag indicating whether to force a refresh of data.
 * @param {Function} onEdit - Callback function for the edit action.
 * @returns {object} An object containing arrays of column configurations for different views.
 */
const useColumns = (materialId, forceRefresh, onEdit) => {
  const { t } = useTranslation();
  const { deleteSlice } = useCatalogMaterialContext();

  const renderPriceThickness = (slices, thickness) => {
    const defaultValue = t('materials.form.no_thickness_found');
    let sortedPrices;
    if (!slices) return defaultValue;
    const sliceThickness = slices.find(
      (slice) => slice.thickness === thickness
    );

    if (sliceThickness) {
      sortedPrices = sliceThickness.prices_management.sort(
        (a, b) => new Date(b.application_date) - new Date(a.application_date)
      );
    }

    return sortedPrices?.length > 0
      ? sortedPrices[0]?.sell_price
      : defaultValue;
  };

  const basicColumns = [
    {
      title: t('materials.table.denomination'),
      dataIndex: 'denomination',
      key: 'denomination',
      sorter: (a, b) => a?.denomination.localeCompare(b?.denomination)
    },
    {
      title: t('materials.table.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => (category ? category.title : ''),
      sorter: (a, b) => a?.category.title.localeCompare(b?.category.title)
    },
    {
      title: t('materials.table.finishing_product'),
      dataIndex: 'finishing_product',
      key: 'finishing_product',
      render: (finishingProduct) =>
        finishingProduct ? finishingProduct.title : '',
      sorter: (a, b) =>
        a?.finishing_product.title.localeCompare(b?.finishing_product.title)
    },
    {
      title: t('materials.table.thickness2'),
      dataIndex: 'slices_management',
      key: 'slices_management',
      render: (slices) => renderPriceThickness(slices, 20)
    },
    {
      title: t('materials.table.thickness3'),
      dataIndex: 'slices_management',
      key: 'slices_management',
      render: (slices) => renderPriceThickness(slices, 30)
    }
  ];

  const slicesManagementColumns = [
    {
      title: t('materials.form.slices_management.nomenclature'),
      dataIndex: 'nomenclature',
      key: 'nomenclature',
      sorter: (a, b) => a?.nomenclature.localeCompare(b?.nomenclature)
    },
    {
      title: t('materials.form.slices_management.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => (a.price < b.price ? -1 : 1)
    },
    {
      title: t('materials.form.slices_management.unit_measure'),
      dataIndex: ['unit_measure', 'title'],
      key: 'unit_measure',
      sorter: (a, b) => a?.unit_measure.localeCompare(b?.unit_measure)
    },
    {
      title: t('materials.form.slices_management.thickness'),
      dataIndex: 'thickness',
      key: 'thickness',
      sorter: (a, b) => (a.thickness < b.thickness ? -1 : 1)
    },
    {
      title: t('materials.form.slices_management.length'),
      dataIndex: 'length',
      key: 'length',
      sorter: (a, b) => (a.length < b.length ? -1 : 1)
    },
    {
      title: t('materials.form.slices_management.width'),
      dataIndex: 'width',
      key: 'width',
      sorter: (a, b) => (a < b ? -1 : 1)
    },
    {
      title: t('materials.form.slices_management.height'),
      dataIndex: 'height',
      key: 'height',
      sorter: (a, b) => (a < b ? -1 : 1)
    },
    {
      title: t('materials.form.slices_management.quotation_height_short'),
      dataIndex: 'quotation_height',
      key: 'quotation_height',
      sorter: (a, b) => (a < b ? -1 : 1)
    },
    {
      key: 'action',
      align: 'right',
      render: (_, row) => (
        <ActionSliceManagement
          editAction={() => onEdit('slices', row)}
          deleteAction={() => deleteSlice(materialId, row?._id, forceRefresh)}
        />
      )
    }
  ];

  const pricesManagementColumns = [
    {
      title: t('materials.form.prices_management.application_date'),
      dataIndex: 'application_date',
      key: 'application_date',
      sorter: (a, b) =>
        moment(a.purchase_date).format('YYYYMMDD') <
        moment(b.purchase_date).format('YYYYMMDD')
          ? -1
          : 1,
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: t('materials.form.prices_management.purchase_date'),
      dataIndex: 'purchase_date',
      key: 'purchase_date',
      sorter: (a, b) =>
        moment(a.purchase_date).format('YYYYMMDD') <
        moment(b.purchase_date).format('YYYYMMDD')
          ? -1
          : 1,
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: t('materials.form.prices_management.purchase_price'),
      dataIndex: 'purchase_price',
      key: 'purchase_price',
      sorter: (a, b) => (a.purchase_price < b.purchase_price ? -1 : 1),
      render: (purchase_price) => `${purchase_price}€`
    },
    {
      title: t('materials.form.prices_management.transport_cost'),
      dataIndex: 'transport_cost',
      key: 'transport_cost',
      sorter: (a, b) => (a.transport_cost < b.transport_cost ? -1 : 1),
      render: (transport_cost) => `${transport_cost}€`
    },
    {
      title: t('materials.form.prices_management.material_coefficient'),
      key: 'material_coefficient',
      dataIndex: 'material_coefficient',
      sorter: (a, b) =>
        a.material_coefficient < b.material_coefficient ? -1 : 1
    },
    {
      title: t('materials.form.prices_management.sell_price'),
      dataIndex: 'sell_price',
      key: 'sell_price',
      sorter: (a, b) => (a.sell_price < b.sell_price ? -1 : 1),
      render: (sell_price) => `${sell_price}€`
    },
    {
      key: 'action',
      align: 'right',
      render: (row) => (
        <ActionSliceManagement
          editAction={() => onEdit('prices', row.sliceInfo, row)}
          deleteAction={() =>
            deleteSlice(materialId, row?.sliceInfo?._id, forceRefresh, row?._id)
          }
        />
      )
    }
  ];

  return {
    basicColumns,
    pricesManagementColumns,
    slicesManagementColumns
  };
};

export default useColumns;

import React, { useState, useEffect } from 'react';
import { Divider, Popconfirm, Row, Tag, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { projectsStatus } from '../../utils/constants/tagColors';
import { routes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

/**
 * Hook: useColumns
 *
 * A custom hook that provides configuration and logic for displaying columns in a projects table.
 * This hook prepares columns for displaying projects' details such as project type, address,
 * dwelling type, supervisors, and status. Additionally, it offers action buttons for each row
 * like view, edit, and delete/archive.
 *
 * Furthermore, it also performs API requests to fetch necessary enumerations and other
 * metadata required for certain columns.
 *
 * @hook
 *
 * @property {Array} basicColumns - Array of configurations for main columns.
 * @property {Array} actionColumn - Array of configurations for action columns (buttons).
 * @property {boolean} forceRefresh - A state variable to re-render the component.
 * @property {Function} setForceRefresh - Setter for forceRefresh state.
 *
 * @returns {Object}
 * - basicColumns (Array): Configurations for main columns which display the core information about projects.
 * - actionColumn (Array): Configurations for action columns which include buttons for various operations on a project.
 * - forceRefresh (boolean): A state variable indicating if the component/table should be refreshed. Useful after certain operations.
 * - setForceRefresh (Function): Setter function to toggle forceRefresh, triggering a component/table re-render.
 * */
const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [dwellingTypes, setDwellingTypes] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const deleteProject = async (id) => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('PATCH', {
        url: `projects/${id}`,
        body: { status: 'ARCHIVED' }
      });
    } catch (e) {
      message(e);
    }
  };

  const getSupervisors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `projects?populate=MP_supervisor`
      });
      setSupervisors(data.map((project) => project.MP_supervisor));
      setDwellingTypes(data.map((project) => project.dwelling_type));
    } catch (e) {
      message(e);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `projects/enums`
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSupervisors();
      await getEnums();
    })();
  }, []);

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `${routes.PROJECTS}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>

          <>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `${routes.PROJECTS}/edit/${record._id}`
              }}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          </>
          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() => deleteProject(record?._id)}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: '#b51010', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];

  const basicColumns = [
    {
      title: t('projects.table.project_type'),
      dataIndex: 'project_type',
      index: 'project_type',
      key: 'project_type',
      sorter: true,
      render: (type) => type && type.title
    },
    {
      title: t('projects.table.address'),
      dataIndex: 'site_address',
      key: 'site_address',
      render: (address) =>
        address && (
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <Row>{address.street}</Row>
            <Row>{`${address.postal_code} ${address.city}`}</Row>
            {address.additional ? <Row> {address.additional}</Row> : null}
          </Row>
        )
    },
    {
      title: t('projects.table.dwelling_type'),
      dataIndex: 'dwelling_type',
      key: 'dwelling_type',
      sorter: (a, b) => a?.type.localeCompare(b?.type),
      filters: dwellingTypes?.map((type) => ({
        text: type,
        value: type
      }))
    },
    {
      title: t('projects.table.MP_supervisor'),
      dataIndex: 'MP_supervisor',
      key: 'MP_supervisor',
      render: (supervisor) => (
        <Select>
          <Select.Option
            value={`${supervisor.first_name} ${supervisor.last_name}`}
          >
            {`${supervisor.first_name} ${supervisor.last_name}`}
          </Select.Option>
        </Select>
      ),
      filters: supervisors.map((supervisor) => ({
        text: `${supervisor.first_name} ${supervisor.last_name}`,
        value: `${supervisor.first_name} ${supervisor.last_name}`
      })),
      sorter: (a, b) =>
        a.MP_supervisor.last_name.localeCompare(b.MP_supervisor.last_name)
    },
    {
      title: t('projects.table.status'),
      dataIndex: 'status',
      key: 'status',
      filters: enums?.statuses?.map((status) => ({
        text: t(`customers.form.${status}`),
        value: status
      })),
      render: (type) =>
        (type && (
          <Tag color={projectsStatus[type]}>{t(`projects.form.${type}`)}</Tag>
        )) ||
        t('errors.form.not_specified'),
      sorter: (a, b) => a?.status.localeCompare(b?.status)
    }
  ];

  return {
    basicColumns,
    actionColumn,
    forceRefresh,
    setForceRefresh
  };
};

export default useColumns;

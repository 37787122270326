import { useTranslation } from 'react-i18next';

/**
 * Custom hook to generate columns configuration for services table.
 *
 * @hook
 *
 * @returns {Array} An array of column configuration objects.
 */
const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('services.form.nomenclature'),
      dataIndex: 'nomenclature',
      key: 'nomenclature',
      sorter: (a, b) => a?.category.title.localeCompare(b?.category.title)
    },
    {
      title: t('services.form.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => category && category.title,
      sorter: (a, b) => a?.category.title.localeCompare(b?.category.title)
    },
    {
      title: t('services.form.unit'),
      dataIndex: 'unit',
      key: 'unit',
      render: (unit) => unit && unit.title
    },
    {
      title: t('services.form.price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => (a.price < b.price ? -1 : 1)
    }
  ];
};

export default useColumns;

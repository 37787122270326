import moment from 'moment';

/**
 * Formats invoice data for the advance table.
 *
 * @param {Object} datas - The invoice data to be formatted.
 * @param {function} t - The translation function for localization.
 * @returns {Array} An array of objects containing formatted invoice data.
 */
export const formatAdvanceTableData = (datas, t) => [
  {
    label: `${
      datas?.invoice_type === 'ADVANCE'
        ? t('invoices.show.advance_of')
        : t('invoices.show.init_advance_of')
    }${datas?.totals?.advance_total}€ ${t('invoices.show.on_order')}${
      datas?.order?.number
    } ${t('invoices.show.of')} ${moment(datas.body_infos.emission_date).format(
      'DD-MM-YYYY'
    )}`,
    total: datas.totals.advance_total
  }
];

import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { customerTypes } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Custom hook for defining table columns for displaying customer data.
 *
 * @hook
 *
 * @returns {Array} An array of column objects.
 */
const useColumns = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/customers/enums' });
      setEnums(data.type);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('customers.table.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a?.type.localeCompare(b?.type),
      filters: enums.map((type) => ({
        text: t(`customers.form.${type}`),
        value: type
      })),
      render: (type) =>
        (type && (
          <Tag color={customerTypes[type]}>{t(`customers.form.${type}`)}</Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      title: t('customers.table.last_name'),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a?.last_name.localeCompare(b?.last_name)
    },
    {
      title: t('customers.table.first_name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a?.first_name.localeCompare(b?.first_name)
    },
    {
      title: t('customers.table.phone'),
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number.number &&
        `${phone_number.country_code} ${phone_number.number.slice(1)}`
    },
    {
      title: t('customers.table.email'),
      dataIndex: 'email'
    }
  ];
};

export default useColumns;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { formatterFile } from '../../utils/formatters/importFile';

export const ExportCSV = ({
  datas,
  dataName,
  fileName,
  headers,
  fetchData,
  setVisible
}) => {
  const { t } = useTranslation();
  const { flattenObject } = formatterFile;
  const [isLoading, setIsLoading] = useState(false);

  const flattenData = datas.map((data) => flattenObject(data));

  return (
    <CSVLink
      style={{ color: 'inherit' }}
      loading={isLoading.toString()}
      asyncOnClick
      onClick={async (e, done) => {
        if (!datas.length) {
          setIsLoading(true);
          e.persist();
          e.preventDefault();
          try {
            await fetchData();
            setIsLoading(false);
            setVisible(false);
            e.target.click();
            done(false);
          } catch (error) {
            setIsLoading(false);
            done(true);
          }
        } else done();
      }}
      filename={fileName}
      data={flattenData}
      headers={(headers || []).map(({ label, key }) => ({
        label: t(`${dataName}.form.${label}`),
        key: key || label
      }))}
    >
      <Button type="link" icon={<DownloadOutlined />}>
        {t(`buttons.export.csv`)}
      </Button>
    </CSVLink>
  );
};

ExportCSV.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  datas: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  fetchData: PropTypes.func.isRequired,
  setVisible: PropTypes.func
};

ExportCSV.defaultProps = {
  setVisible: () => {}
};

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, Button, Space, Row } from 'antd';
import {
  ContainerOutlined,
  DownloadOutlined,
  DownOutlined
} from '@ant-design/icons';
import { renderStatusButton } from './renderStatusButton';
import { useDownloadDocument } from '../../../utils/downloadDoc';

/**
 * ExtraButtons is a component that renders additional buttons for performing actions on a quotation.
 *
 * @component
 * @param {object} quotation - The quotation object.
 * @param {function} patchQuotationStatus - Function to update the quotation status.
 * @returns {JSX.Element} ExtraButtons component
 */
export const ExtraButtons = ({ quotation, patchQuotationStatus }) => {
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();

  const items = [
    // {
    //   label: t('buttons.detailed_quotation'),
    //   key: 'detailed_quotation'
    // },
    {
      label: t('buttons.quotation_without_details'),
      key: 'quotation_without_details'
    }
  ];

  const menuProps = {
    items,
    onClick: ({ key }) => downloadDocument(quotation[key])
  };

  return (
    <Row>
      <Space wrap>
        {renderStatusButton(quotation, patchQuotationStatus)}
        <Dropdown menu={menuProps}>
          <Button>
            <DownloadOutlined />
            {t('buttons.download')}
            <DownOutlined />
          </Button>
        </Dropdown>
        <Button danger onClick={() => patchQuotationStatus('toArchived')}>
          <ContainerOutlined />
          {t('buttons.archive')}
        </Button>
      </Space>
    </Row>
  );
};

ExtraButtons.propTypes = {
  quotation: PropTypes.shape({
    status: PropTypes.string,
    kanban_status: PropTypes.string
  }),
  patchQuotationStatus: PropTypes.func
};

ExtraButtons.defaultProps = {
  quotation: null,
  patchQuotationStatus: null
};

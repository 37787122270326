import { useTranslation } from 'react-i18next';
import { Row, Select, Switch } from 'antd';
import PropTypes from 'prop-types';
import { useOrderContext } from '../../contexts/OrderContext';

/**
 * Represents the header component for filtering and switching between kanban and list views.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isKanbanView - Indicates whether the current view is kanban view.
 * @param {function} props.setIsKanbanView - Function to set the kanban view mode.
 * @param {function} props.setCustomerFilter - Function to set the customer filter.
 * @param {function} props.setStatusFilter - Function to set the status filter.
 * @returns {JSX.Element} A JSX element representing the OrderHeader component.
 */
export const OrderHeader = ({
  isKanbanView,
  setIsKanbanView,
  setCustomerFilter,
  setStatusFilter
}) => {
  const { t } = useTranslation();
  const { customers, statuses, kanbanStatus } = useOrderContext();

  const allStatuses = statuses
    .filter((item) => item !== 'KANBAN_STATUS')
    .map((item) => ({
      label: t(`orders.form.${item}`),
      value: item,
      key: item,
      type: 'status'
    }));

  const allKanbanStatuses = kanbanStatus.map(({ _id, title }) => ({
    label: title,
    value: _id,
    key: _id,
    type: 'kanban_status'
  }));

  const statusOptions = [...allStatuses, ...allKanbanStatuses];

  return (
    <Row justify="space-between" align="middle" style={{ flexWrap: 'nowrap' }}>
      <Row align="middle" style={{ flexWrap: 'nowrap' }}>
        {t('quotations.form.filters')}
        <Select
          allowClear
          style={{ width: 150, marginRight: 8 }}
          onSelect={(_, { type, value }) => {
            setStatusFilter(`${type}=${value}`);
          }}
          onClear={() => setStatusFilter('')}
          placeholder={t('quotations.form.status')}
          options={statusOptions}
        />
        <Select
          allowClear
          onSelect={(value) => {
            setCustomerFilter(value);
          }}
          onClear={() => setCustomerFilter('')}
          style={{ width: 150 }}
          placeholder={t('quotations.form.customer_arr')}
        >
          {customers.map((customer) => (
            <Select.Option key={customer} value={customer._id}>
              {`${customer.first_name} ${customer.last_name}`}
            </Select.Option>
          ))}
        </Select>
      </Row>
      <Row>
        <span className={isKanbanView ? 'active-switch-label' : ''}>
          {t('quotations.form.kanban_view')}
        </span>
        <Switch
          checked={!isKanbanView}
          onChange={() => setIsKanbanView(!isKanbanView)}
        />
        <span className={!isKanbanView ? 'active-switch-label' : ''}>
          {t('quotations.form.list_view')}
        </span>
      </Row>
    </Row>
  );
};

OrderHeader.propTypes = {
  isKanbanView: PropTypes.bool,
  setIsKanbanView: PropTypes.func,
  setCustomerFilter: PropTypes.func,
  setStatusFilter: PropTypes.func
};

OrderHeader.defaultProps = {
  isKanbanView: false,
  setIsKanbanView: null,
  setCustomerFilter: null,
  setStatusFilter: null
};

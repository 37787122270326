export const headers = [
  {
    label: 'type',
    key: 'type',
    display: true,
    required: true
  },
  {
    label: 'last_name',
    key: 'last_name',
    display: true,
    required: true
  },
  {
    label: 'first_name',
    key: 'first_name',
    display: true,
    required: true
  },
  {
    label: 'phone_number',
    key: 'phone_number',
    display: true,
    required: true
  },
  {
    label: 'email',
    key: 'email',
    display: true,
    required: true
  },
  {
    label: 'commission',
    key: 'commission',
    display: true,
    required: true
  },
  {
    label: 'street',
    key: 'billing_address.street',
    display: true,
    required: true
  },
  {
    label: 'postal_code',
    key: 'billing_address.postal_code',
    display: true,
    required: true
  },
  {
    label: 'city',
    key: 'billing_address.city',
    display: true,
    required: true
  },
  {
    label: 'business_provider',
    key: 'business_provider',
    display: true,
    required: true
  },
  {
    label: 'coefficient',
    key: 'coefficient',
    display: true,
    required: false
  },
  {
    label: 'remark',
    key: 'remark',
    display: true,
    required: false
  }
];

import { useTranslation } from 'react-i18next';

/**
 * Generates a list of label and content pairs to display invoice totals information.
 *
 * @hook
 *
 * @param {Object} datas - The invoice totals data to display.
 * @param {string} source - Type of the invoice
 * @returns {Array} An array of label and content pairs.
 */
export const useTotalsListContent = (datas) => {
  const { t } = useTranslation();

  const basicTotals = datas && [
    {
      label: t('invoices.show.totals_infos.additional_discount'),
      span: 3,
      content:
        (datas?.extra_addition &&
          `${Number(datas.extra_addition).toFixed(2)}€`) ||
        '0€'
    },
    {
      label: t('invoices.show.totals_infos.discountHT'),
      span: 2,
      content:
        (datas?.extra_discount &&
          `${Number(datas.extra_discount).toFixed(2)}€`) ||
        '0€'
    },
    {
      label: t('invoices.show.totals_infos.totalHT'),
      span: 2,
      content:
        (datas?.HT_total && `${Number(datas.HT_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.totals_infos.totalTva'),
      span: 2,
      content:
        (datas?.VAT_total && `${Number(datas.VAT_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.totals_infos.totalTTC'),
      span: 2,
      content:
        (datas?.all_included_total &&
          `${Number(datas.all_included_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.totals_infos.payed'),
      span: 2,
      content: (datas?.paid && `${Number(datas.paid).toFixed(2)}€`) || '0€'
    },
    {
      label: t('invoices.show.totals_infos.remaining'),
      span: 2,
      content:
        (datas?.due !== undefined && `${Number(datas.due).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    }
  ];

  const advanceTotals = [
    {
      label: t('invoices.show.totals_infos.totalTTC'),
      span: 2,
      content:
        (datas?.advance_total &&
          `${Number(datas.advance_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    }
  ];

  const creditTotals = [
    {
      label: t('invoices.show.totals_infos.totalHT'),
      span: 2,
      content:
        (datas?.HT_total && `-${Number(datas.HT_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.totals_infos.VAT_rate'),
      span: 2,
      content:
        (datas?.VAT_rate && `${datas.VAT_rate.percentage}%`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.totals_infos.totalTva'),
      span: 2,
      content:
        (datas?.VAT_total && `-${Number(datas.VAT_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('invoices.show.totals_infos.totalTTC'),
      span: 2,
      content:
        (datas?.all_included_total &&
          `-${Number(datas.all_included_total).toFixed(2)}€`) ||
        t('errors.form.not_specified')
    }
  ];

  return {
    basicTotals,
    advanceTotals,
    creditTotals
  };
};

import * as XLSX from 'xlsx';
import { message } from 'antd';

const isDate = (value) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (typeof value === 'string') return dateRegex.test(value);
  return false;
};

const isNumberWithDecimal = (value) => /^-?\d+\.\d+$/.test(value);

const setJsonWithKey = (header, datas) => {
  const newHeaderKey = datas
    .slice(1)
    .map((row) =>
      row.reduce((acc, value, index) => {
        const normalizedValue = value || null;
        acc[header[index]] = normalizedValue;
        return acc;
      }, {})
    )
    .filter((obj) => !('undefined' in obj));

  return newHeaderKey.filter((item) => Object.keys(item).length !== 0);
};

const flattenObject = (obj, parentKey = '') =>
  Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null)
      return { ...acc, ...flattenObject(obj[key], newKey) };
    return { ...acc, [newKey]: obj[key] };
  }, {});

const unflattenObject = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const keys = key.split('.');
    const lastKey = keys.pop();

    const nestedObj = keys.reduce((nesteAcc, nestedKey) => {
      const nestedAcc = nesteAcc;
      if (!nestedAcc[nestedKey]) nestedAcc[nestedKey] = {};
      return nestedAcc[nestedKey];
    }, acc);

    if (isNumberWithDecimal(value)) nestedObj[lastKey] = parseFloat(value);
    else if (isDate(value)) nestedObj[lastKey] = new Date(value);
    else nestedObj[lastKey] = value;

    return acc;
  }, {});

const filterByTranslation = (toFilter, filterList, titleTrad, t) =>
  toFilter
    .map(
      (head) =>
        filterList.find((item) => t(`${titleTrad}.form.${item.label}`) === head)
          ?.key
    )
    .filter(Boolean);

const checkFileType = (file, accept, t) => {
  const accepted = accept || ['*'];
  if (accepted.includes('*') || accepted.includes(file.name.split('.').pop()))
    return true;
  message.error(t('errors.message.templates.extension'));
  return false;
};

const xlsxFormatter = async (file, headers, resourceName, t) => {
  const arrayBuffer = await file.arrayBuffer();
  const data = new Uint8Array(arrayBuffer);
  const workbook = XLSX.read(data, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  const filteredHeaders = filterByTranslation(
    jsonData[0],
    headers,
    resourceName,
    t
  );

  const jsonDataWithNewHeaders = setJsonWithKey(filteredHeaders, jsonData);

  const missingRequiredIndexes = jsonDataWithNewHeaders
    .map((obj, index) =>
      headers.some((item) => item.required && !(item.key in obj))
        ? index + 1
        : -1
    )
    .filter((index) => index !== -1);

  if (missingRequiredIndexes.length) {
    message.error(
      t(
        `import.messages.list-line${
          missingRequiredIndexes.length === 1 ? '' : 's'
        }`,
        {
          list: `${missingRequiredIndexes.join(', ')}`
        },
        5
      )
    );
    return false;
  }
  return jsonDataWithNewHeaders;
};

export const formatterFile = {
  flattenObject,
  isDate,
  isNumberWithDecimal,
  setJsonWithKey,
  unflattenObject,
  filterByTranslation,
  xlsxFormatter,
  checkFileType
};

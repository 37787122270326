import { useTranslation } from 'react-i18next';

/**
 * useShapingsColumns is a custom hook that returns an array of column configurations for displaying shaping-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useShapingsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      key: 'index',
      dataIndex: 'index',
      render: (index) => `${t('quotations.table.shaping')} ${index + 1}`
    },
    {
      title: t('quotations.table.shaping_catalog'),
      key: 'shaping_catalog',
      dataIndex: 'shaping_catalog',
      render: (shaping) => shaping && shaping.nomenclature
    },
    {
      title: t('quotations.table.quantity'),
      key: 'quantity',
      dataIndex: 'quantity'
    },
    {
      title: t('quotations.table.unit'),
      key: 'unit',
      dataIndex: 'unit',
      render: (unit) => unit && unit.title
    },
    {
      title: t('quotations.table.unprocessed_value'),
      key: 'shaping_catalog',
      dataIndex: 'shaping_catalog',
      render: (shaping) => shaping && `${shaping.price}€`
    },
    {
      title: t('quotations.table.customer_coefficient_value'),
      key: 'customer_coefficient_value',
      dataIndex: 'customer_coefficient_value',
      render: (value) => value && `${value}€`
    },
    {
      title: t('quotations.table.commission'),
      key: 'commission',
      dataIndex: 'commission',
      render: (rate) => rate && `${rate}%`
    },
    {
      title: t('quotations.table.facial_value'),
      key: 'facial_value',
      dataIndex: 'facial_value',
      render: (value) => value && `${value}€`
    },
    {
      title: t('quotations.table.shaping_VAT_rate'),
      key: 'shaping_VAT_rate',
      dataIndex: 'shaping_VAT_rate',
      render: (rate) => rate && `${rate.percentage}%`
    },
    {
      title: t('quotations.table.all_included_value'),
      key: 'all_included_value',
      dataIndex: 'all_included_value',
      render: (value) => value && `${value.toFixed(2)}€`
    }
  ];
};

import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Tag } from 'antd';
import { customCaseConversion } from './Show/customCaseConversion';

/**
 * Custom hook to generate column configurations for displaying orders in a table.
 *
 * @hook
 * @returns {Object[]} An array of column configurations for displaying orders.
 */
export const useOrderColumns = () => {
  const { t } = useTranslation();

  const colorStatus = '#ababab';

  return [
    {
      title: t('orders.column.customer'),
      dataIndex: 'customer',
      key: 'customer',
      sorter: true,
      render: ({ last_name, first_name }) => `${last_name} ${first_name}`
    },
    {
      title: t('orders.column.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, { status, kanban_status }) => {
        switch (true) {
          case status !== 'KANBAN_STATUS':
            return (
              <Tag color={colorStatus}>{t(`orders.column.${status}`)}</Tag>
            );
          case status === 'KANBAN_STATUS':
            return (
              <Tag color={kanban_status.color}>
                {customCaseConversion(kanban_status.title)}
              </Tag>
            );
          default:
            return t('errors.form.not_specified');
        }
      }
    },
    {
      title: t('orders.column.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      render: (number) =>
        (
          <Tag
            style={{
              color: 'var(--primaryColor)'
            }}
          >
            {number}
          </Tag>
        ) || t('errors.form.not_specified')
    },
    {
      title: t('orders.column.construction_date'),
      dataIndex: 'construction_date',
      key: 'construction_date',
      sorter: true,
      render: (date) =>
        (date && moment(date[0]).format('DD/MM/YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      title: t('orders.column.worksite_address'),
      dataIndex: 'worksite_address',
      render: ({ city }) => city || t('errors.form.not_specified')
    },
    {
      title: t('orders.column.amount'),
      dataIndex: 'totals',
      render: ({ totalTTC }) => totalTTC || t('errors.form.not_specified')
    },
    {
      title: t('orders.column.payment'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      sorter: true,
      render: (status) =>
        <Tag>{t(`orders.column.payment_status.${status}`)}</Tag> ||
        t('errors.form.not_specified')
    }
  ];
};

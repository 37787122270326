import React from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './formatter/headers';
import { formatUsers } from './formatter/formatUser';

export const ListUsers = () => {
  const columns = useColumns();
  const { formatUser } = formatUsers();

  return (
    <ListResource
      resourceName="users"
      columns={columns}
      headers={headers}
      withArchiveButton
      withImportButton
      formatter={formatUser}
      resourceModelName="User"
    />
  );
};

import React from 'react';
import useColumns from './columns';
import { ListResource } from '../../../components/ListResource/ListResource';
import { ExtraFilters } from '../ExtraFilters';
import { useServiceContext } from '../../../contexts/ServiceContext';
import { DownloadFileButtons } from '../../../components/DownloadFileButton/DownloadFileButton';
import { headers } from './formatter/headers';

/**
 * Component for displaying a list of services and their details.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Services component.
 */
export const Services = () => {
  const columns = useColumns();
  const { importTemplate } = useServiceContext();

  return (
    <ListResource
      columns={columns}
      withArchiveButton
      withImportButton
      extraFilters={
        <ExtraFilters resourceModelName="service" translationName="services" />
      }
      populate="category,unit"
      resourceName="services"
      resourceModelName="Service"
      headers={headers}
      extraMenu={
        importTemplate &&
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
    />
  );
};

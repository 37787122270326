import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const QuotationDrawer = ({ showDrawer, openDrawer }) => {
  const { t } = useTranslation();
  return (
    <div className="quotation-drawer-button">
      {!openDrawer ? (
        <Button onClick={showDrawer}>
          <LeftOutlined />
        </Button>
      ) : (
        <Row className="quotation-drawer">
          <Col
            span={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button onClick={showDrawer}>
              <RightOutlined />
            </Button>
          </Col>
          <Col span={18}>
            <Form.Item
              className="quotation-price_conditions-title"
              label={t(`quotations.form.summary.total.title.base`)}
            >
              <Form.Item
                name={['summary', 'base_total', 'unprocessed_values_total']}
                label={t(
                  'quotations.form.summary.total.unprocessed_values_total'
                )}
              >
                <Input type="number" disabled bordered={false} suffix="€" />
              </Form.Item>
              <Form.Item
                name={['summary', 'base_total', 'commission_total']}
                label={t('quotations.form.summary.total.commission_total')}
              >
                <Input type="number" disabled bordered={false} suffix="€" />
              </Form.Item>
              <Form.Item
                name={['summary', 'base_total', 'total_excluding_VAT']}
                label={t('quotations.form.summary.total.total_excluding_VAT')}
              >
                <Input type="number" disabled bordered={false} suffix="€" />
              </Form.Item>
            </Form.Item>
            <Form.Item
              className="quotation-price_conditions-title"
              label={t(`quotations.form.summary.total.title.option`)}
            >
              <Form.Item
                name={['summary', 'option_total', 'unprocessed_values_total']}
                label={t(
                  'quotations.form.summary.total.unprocessed_values_total'
                )}
              >
                <Input type="number" disabled bordered={false} suffix="€" />
              </Form.Item>
              <Form.Item
                name={['summary', 'option_total', 'commission_total']}
                label={t('quotations.form.summary.total.commission_total')}
              >
                <Input type="number" disabled bordered={false} suffix="€" />
              </Form.Item>
              <Form.Item
                name={['summary', 'option_total', 'total_excluding_VAT']}
                label={t('quotations.form.summary.total.total_excluding_VAT')}
              >
                <Input type="number" disabled bordered={false} suffix="€" />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};

QuotationDrawer.propTypes = {
  showDrawer: PropTypes.func,
  openDrawer: PropTypes.bool
};

QuotationDrawer.defaultProps = {
  showDrawer: null,
  openDrawer: false
};

import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const OrderContext = createContext({ isValid: false });

export const OrderContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [customers, setCustomers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [kanbanStatus, setKanbanStatus] = useState([]);
  const [importTemplate, setImportTemplate] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          customersResponse,
          statusesResponse,
          kanbanStatusesResponse,
          importTemplateResponse
        ] = await Promise.all([
          dispatchAPI('GET', { url: 'customers' }),
          dispatchAPI('GET', { url: 'orders/enums' }),
          dispatchAPI('GET', { url: 'kanbanview-actions' }),
          dispatchAPI('GET', {
            url: 'template-import?populate=file&type=ORDER'
          })
        ]);

        setCustomers(customersResponse.data);
        setStatuses(statusesResponse.data.status);
        setKanbanStatus(kanbanStatusesResponse.data);
        setImportTemplate(importTemplateResponse.data);
      } catch (e) {
        message(e);
      }
    };

    fetchData();
  }, []);

  return (
    <OrderContext.Provider
      value={{
        customers,
        setCustomers,
        statuses,
        setStatuses,
        kanbanStatus,
        setKanbanStatus,
        importTemplate,
        setImportTemplate
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};

import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuotationContext } from '../../../../contexts/QuotationContext';
import { ShapingForm } from './ShapingForm';

const { TextArea } = Input;

export const VolumeItem = ({
  name,
  materialProvidedByCustomer,
  restField,
  form
}) => {
  const { t } = useTranslation();
  const {
    VATRates,
    selectedRate,
    globalCommission,
    globalMaterial,
    materials,
    pickedTypeOfWork,
    typeOfWorks
  } = useQuotationContext();

  const [materialID, setMaterialID] = useState(globalMaterial || '');
  const [isOption, setIsOption] = useState(false);
  const [thicknesses, setThicknesses] = useState(0);
  const [shapingFilter, setShapingFilter] = useState();

  const [slice, setSlice] = useState({
    shapingCoefficient: 1,
    maxWidth: 0,
    maxLength: 0
  });
  const [wishedLength, setWishedLength] = useState(0);
  const [wishedWidthOrHeight, setWishedWidthOrHeight] = useState(0);
  const [surface, setSurface] = useState(0);

  const handleSurfaceCalculation = () => {
    const calculatedSurface =
      (wishedLength / 1000) * (wishedWidthOrHeight / 1000);
    return Number.isNaN(calculatedSurface) ? 0 : calculatedSurface;
  };

  useEffect(() => {
    const calculatedSurface = handleSurfaceCalculation();

    if (wishedLength && wishedWidthOrHeight)
      form.setFields([
        {
          name: ['materials', name, 'surface'],
          value: Number(calculatedSurface.toFixed(2))
        }
      ]);

    setSurface(calculatedSurface);
  }, [wishedLength, wishedWidthOrHeight]);

  const handleMaterialChoice = () => {
    let item;
    setThicknesses();
    if (materialID) {
      item = materials.find((material) => material._id === materialID);
    } else {
      item = materials.find((material) => material._id === globalMaterial);
    }
    setShapingFilter(item?.category?.title || '');
    setThicknesses(item?.slices_management);
  };

  const handleDimensions = (value) => {
    const sliceUnit = thicknesses.find((thickness) => thickness._id === value);
    setSlice({
      maxLength: sliceUnit.length,
      maxWidth: sliceUnit.width || sliceUnit.height,
      isLinear: !sliceUnit.width
    });
  };

  useEffect(() => {
    handleMaterialChoice();
  }, [materialID, globalMaterial, materials]);

  return (
    <Row key={name} style={{ flexDirection: 'column' }}>
      <Row>
        <Form.Item {...restField} name={[name, 'is_option']}>
          {t('quotations.form.materials.base')}
          <Form.Item name={[name, 'is_option']} initialValue={isOption}>
            <Switch onChange={() => setIsOption(!isOption)} />
          </Form.Item>
          {t('quotations.form.materials.option')}
        </Form.Item>
      </Row>
      {!materialProvidedByCustomer && (
        <Row gutter={149}>
          <Col style={{ width: 'column' }}>
            <>
              <Form.Item
                name={[name, 'piece_type']}
                label={t('quotations.form.materials.piece_type')}
              >
                <Select>
                  {(typeOfWorks || [])
                    .filter((type) => type._id === pickedTypeOfWork)
                    .map((pieceType) =>
                      pieceType.type_of_pieces.map((piece) => (
                        <Select.Option key={piece._id} value={piece.title}>
                          {piece.title}
                        </Select.Option>
                      ))
                    )}
                </Select>
              </Form.Item>
              <Form.Item
                name={[name, 'materials_catalog']}
                label={t('quotations.form.materials.materials_catalog')}
                {...restField}
                initialValue={materialID}
              >
                <Select
                  onChange={(value) => setMaterialID(value)}
                  value={materialID}
                  showSearch
                  optionFilterProp="children"
                >
                  {(materials || []).map((material) => (
                    <Select.Option key={material._id} value={material._id}>
                      {`${material.denomination} ${
                        material.finishing_product
                          ? material.finishing_product.title
                          : ''
                      }`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={[name, 'thickness']}
                label={t('quotations.form.materials.thickness')}
                {...restField}
              >
                <Select onChange={(value) => handleDimensions(value)}>
                  {(thicknesses || []).map((sliceUnit) => (
                    <Select.Option key={sliceUnit._id} value={sliceUnit._id}>
                      {`${sliceUnit.nomenclature} ${sliceUnit.length}x${
                        sliceUnit.width || sliceUnit.height
                      } ép.${sliceUnit.thickness}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {slice && (
                <>
                  <Form.Item
                    name={[name, 'wished_length']}
                    label={t('quotations.form.materials.wished_length')}
                    {...restField}
                  >
                    <InputNumber
                      min={0}
                      max={slice.maxLength}
                      onChange={(value) => setWishedLength(value)}
                      style={{
                        width: '100%'
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'wished_width']}
                    label={
                      !slice.isLinear
                        ? t('quotations.form.materials.wished_width')
                        : t('quotations.form.materials.wished_height')
                    }
                    {...restField}
                  >
                    <InputNumber
                      min={0}
                      max={slice.maxWidth}
                      onChange={(value) => setWishedWidthOrHeight(value)}
                      style={{
                        width: '100%'
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'quantity']}
                    label={t('quotations.form.materials.quantity')}
                    {...restField}
                  >
                    <InputNumber
                      min={0}
                      style={{
                        width: '100%'
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </>
            <Form.Item
              name={[name, 'surface']}
              label={t('quotations.form.materials.surface')}
            >
              <InputNumber
                min={0}
                disabled
                value={surface}
                style={{
                  width: '100%'
                }}
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end'
            }}
          >
            <Form.Item
              name={[name, 'unprocessed_value']}
              label={t('quotations.form.materials.unprocessed_value')}
            >
              <Input type="number" disabled />
            </Form.Item>
            <Form.Item
              name={[name, 'customer_coefficient_value']}
              label={t('quotations.form.materials.customer_coefficient_value')}
            >
              <Input type="number" disabled />
            </Form.Item>
            <Form.Item
              name={[name, 'commission']}
              label={t('quotations.form.materials.commission')}
              initialValue={globalCommission}
            >
              <Input type="number" initialValue={globalCommission} />
            </Form.Item>
            <Form.Item
              name={[name, 'facial_value']}
              label={t('quotations.form.materials.facial_value')}
            >
              <Input type="number" disabled />
            </Form.Item>
            <Form.Item
              name={[name, 'VAT_rate']}
              label={t('quotations.form.materials.VAT_rate')}
              initialValue={selectedRate?._id}
            >
              <Select>
                {(VATRates || []).map((rate) => (
                  <Select.Option key={rate._id} value={rate._id}>
                    {`${rate.percentage}%`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={[name, 'all_included_value']}
              label={t('quotations.form.materials.all_included_value')}
            >
              <Input type="number" disabled />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row className="shaping-form-item">
        <ShapingForm fieldName={name} shapingFilter={shapingFilter} />
      </Row>
      <Row>
        <Form.Item
          name={[name, 'remarks']}
          label={t('quotations.form.materials.remarks')}
          {...restField}
        >
          <TextArea />
        </Form.Item>
      </Row>
    </Row>
  );
};

VolumeItem.propTypes = {
  remove: PropTypes.func,
  name: PropTypes.number,
  materialProvidedByCustomer: PropTypes.bool,
  restField: PropTypes.shape({}),
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  finishingProducts: PropTypes.arrayOf(PropTypes.shape({})),
  projectTypes: PropTypes.arrayOf(PropTypes.shape({})),
  roomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    setFields: PropTypes.func
  })
};

VolumeItem.defaultProps = {
  remove: null,
  name: undefined,
  materialProvidedByCustomer: undefined,
  restField: null,
  materials: null,
  finishingProducts: null,
  projectTypes: null,
  roomTypes: null,
  form: null
};

import { useTranslation } from 'react-i18next';
import ChildrenConfiguration from './ChildrenConfiguration';
import { Configuration } from './Configuration';
import { usePercentageColumn } from './Columns/PercentageColumns';
import { useOnlyTitleColumn } from './Columns/OnlyTitleColumn';
import { useUnitOfMeasuresColumns } from './Columns/UnitOfMeasureColumns';
import { useColorColumns } from './Columns/ColorColumns';

/**
 * Custom hook that generates an array of panels for configuration settings.
 *
 * @hook
 * @returns {Array} An array of panel objects, each containing an element and a title.
 */
export const usePanels = () => {
  const { t } = useTranslation();
  const PercentageColumns = usePercentageColumn();
  const unitOfMeasureColumns = useUnitOfMeasuresColumns();
  const colorColumns = useColorColumns();

  return [
    {
      element: (
        <Configuration
          resourceName="material-categories"
          resourceColumns={useOnlyTitleColumn(
            t('configurations.header.category')
          )}
        />
      ),
      title: t('configurations.form.material-categories')
    },
    {
      element: (
        <Configuration
          resourceName="service-categories"
          resourceColumns={useOnlyTitleColumn(
            t('configurations.header.category')
          )}
        />
      ),
      title: t('configurations.form.service-categories')
    },
    {
      element: (
        <Configuration
          resourceName="accessory-categories"
          resourceColumns={useOnlyTitleColumn(
            t('configurations.header.category')
          )}
        />
      ),
      title: t('configurations.form.accessory-categories')
    },
    {
      element: (
        <Configuration
          resourceName="finishing-products"
          resourceColumns={useOnlyTitleColumn(
            t('configurations.header.finishing-products')
          )}
        />
      ),
      title: t('configurations.form.finishing_products')
    },
    {
      element: (
        <Configuration
          resourceName="rooms"
          resourceColumns={useOnlyTitleColumn(t('configurations.header.type'))}
        />
      ),
      title: t('configurations.form.rooms')
    },
    {
      element: (
        <Configuration
          resourceName="tvas"
          resourceColumns={PercentageColumns}
        />
      ),
      title: t('configurations.form.TVA')
    },
    {
      element: (
        <Configuration
          resourceName="project-types"
          resourceColumns={useOnlyTitleColumn(
            t('configurations.header.project-categories')
          )}
        />
      ),
      title: t('configurations.form.project-categories')
    },
    {
      element: (
        <ChildrenConfiguration
          resourceName="type-of-works"
          childName="type_of_pieces"
        />
      ),
      title: t('configurations.form.type-of-works')
    },
    {
      element: (
        <Configuration
          resourceName="unit-of-measures"
          resourceColumns={unitOfMeasureColumns}
        />
      ),
      title: t('configurations.form.unit-of-measures')
    },
    {
      element: (
        <Configuration
          resourceName="kanbanview-actions"
          resourceColumns={colorColumns}
          addItemButton={false}
        />
      ),
      title: t('configurations.form.kanbanView-actions')
    },
    {
      element: (
        <Configuration
          resourceName="kanbanview-quotes"
          resourceColumns={colorColumns}
          addItemButton={false}
        />
      ),
      title: t('configurations.form.kanbanView-quotes')
    }
  ];
};

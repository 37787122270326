import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components';
import { useOrderContext } from '../../../contexts/OrderContext';
import { StepsStatus } from './StepsStatus';
import { useShowOrderColumns } from './showColumns';
import { OrderHeaderButtons } from './Buttons/OrderHeaderButtons';
import { InfosDetail } from './InfosDetails';
import { routes } from '../../../utils/constants/adminRoutes';
/**
 * Renders a grouped list of information columns.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array} props.datas - Data for grouped columns.
 * @returns {JSX.Element} - The rendered component.
 */
export const ShowOrder = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI, masterRoles } = useAuthContext();
  const { message } = useErrorMessage();
  const { kanbanStatus } = useOrderContext();
  const navigate = useNavigate();

  const [order, setOrder] = useState();
  const [refresh, setRefresh] = useState(false);

  const showOrderColumns = useShowOrderColumns(order, refresh, setRefresh);

  const getOrder = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `orders/${id}?populate=customer,quotations.quotation,kanban_status,project.project_type,type_of_work,invoices`
      });
      setOrder(data);
    } catch (e) {
      message(e);
    }
  };

  const duplicateQuotation = async () => {
    const { quotation } = order.quotations.find(
      (quotationItem) => quotationItem.status === 'ACTIVE'
    );
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/quotations/duplicate/${quotation._id}/${id}`
      });

      navigate(`${routes.QUOTATIONS}/edit/${data._id}`);
    } catch (e) {
      message(e);
    }
  };

  const updateOrder = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `orders/${id}`,
        body
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOrder();
    })();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        title={t('orders.show.details', { number: order?.number })}
        extra={
          masterRoles &&
          OrderHeaderButtons(order, updateOrder, duplicateQuotation)
        }
      />
      <ContentCustom>
        {order && order?.status !== 'ARCHIVED' && (
          <StepsStatus
            resourceName="orders"
            data={order}
            defaultStatus="ORDER_SIGNED"
            status={kanbanStatus}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
        <Divider />
        <InfosDetail datas={showOrderColumns} />
      </ContentCustom>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Tag, Card, Col, Row } from 'antd';
import { useDrag } from 'react-dnd';
import {
  EyeOutlined,
  EditOutlined,
  UserOutlined,
  TagOutlined,
  EuroOutlined
} from '@ant-design/icons';

const iconSize = 18;

/**
 * Represents a Kanban card for displaying order information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.showAction - Determines whether the "View" action is shown.
 * @param {boolean} props.editAction - Determines whether the "Edit" action is shown.
 * @param {Object} props.data - The order data to display.
 * @returns {JSX.Element} A JSX element representing the Kanban card.
 */
export const KanbanCardOrder = ({ data, showAction, editAction }) => {
  const { t } = useTranslation();
  const {
    _id,
    number,
    construction_date,
    customer,
    project,
    worksite_address
  } = data;
  const [, drag] = useDrag({
    item: {
      type: 'card',
      id: _id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <Card
      ref={drag}
      style={{ marginBottom: 10 }}
      bodyStyle={{ padding: 15 }}
      hoverable
    >
      <Row gutter={[16, 16]} style={{ minHeight: 200 }}>
        <Col span={24}>
          <Tag color="default">{number}</Tag>
        </Col>
        <Col span={24}>{project?.denomination || '-'}</Col>
        <Col span={24}>
          {`${t('orders.kanbanview.card.date')} ${
            construction_date
              ? moment(construction_date[0]).format('DD/MM/YYYY')
              : '-'
          }`}
          <br />
          {`${t('orders.kanbanview.card.city')} ${
            worksite_address?.city || '-'
          }`}
        </Col>
        <Row justify="space-around" style={{ width: '100%' }}>
          <Col>
            <UserOutlined style={{ fontSize: iconSize }} />
          </Col>
          <Col>{`${customer.first_name} ${customer.last_name}`}</Col>
          <Col>
            <TagOutlined style={{ fontSize: iconSize }} />
          </Col>
          <Col>
            {showAction && (
              <Link
                to={{
                  pathname: `/orders/show/${_id}`
                }}
              >
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
            )}
          </Col>
          <Col>
            {editAction && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `/order/show/${_id}`
                  }}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
              </>
            )}
          </Col>
          <Col>
            <EuroOutlined style={{ fontSize: iconSize }} />
          </Col>
        </Row>
      </Row>
    </Card>
  );
};

KanbanCardOrder.propTypes = {
  showAction: PropTypes.bool,
  editAction: PropTypes.bool,
  data: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.string,
    construction_date: PropTypes.shape([]),
    worksite_address: PropTypes.shape({
      city: PropTypes.string
    }),
    project: PropTypes.shape({
      denomination: PropTypes.string,
      project_type: PropTypes.shape({
        title: PropTypes.string
      })
    }),
    customer: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }).isRequired
};

KanbanCardOrder.defaultProps = {
  showAction: true,
  editAction: true
};

import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Select,
  Upload,
  message as antdMessage
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  InboxOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const { Dragger } = Upload;
const iconSize = 18;

/**
 * FilesManagement is a component that manages files related to a quotation.
 *
 * @component
 * @param {boolean} forceRefresh - Flag to force a refresh when a file is uploaded.
 * @param {function} setForceRefresh - Function to set the forceRefresh flag.
 * @param {array} documents - List of documents related to the quotation.
 * @returns {JSX.Element} FilesManagement component
 */
export const FilesManagement = ({
  forceRefresh,
  setForceRefresh,
  documents
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const [fileList, setFileList] = useState([]);
  const [fileType, setFileType] = useState();

  const { id } = useParams();

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();

      if (fileList.length) {
        fileList.forEach((file) => {
          formData.append(`${fileType}`, file);
        });
      }

      await dispatchAPI('POST', {
        url: `/quotations/upload/${id}/${fileType}`,
        body: formData
      });
      antdMessage.success(t('quotations.show.success_upload'));
      setForceRefresh(!forceRefresh);
      setFileList([]);
      setFileType();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteFile = async (fileID) => {
    try {
      await dispatchAPI(`DELETE`, { url: `files/${fileID}` });
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  const draggerProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return true;
    },
    fileList
  };

  const editableDocumentTypes = ['VAT_attestation', 'extraDocuments'];
  return (
    <Card title={t('quotations.files.title')}>
      <Row>
        <Col span={18} className="custom-document-list">
          {documents?.map((document) => (
            <Row key={document._id} className="custom-list-item">
              <Col span={19} className="document-meta">
                <Row>
                  <Col span={12} className="custom-list-label">
                    {!document?.file ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )}
                    {document?.title}
                  </Col>
                  <Col span={12} className="customfile">
                    {document?.file?.metadata?.originalName}
                  </Col>
                </Row>
              </Col>
              {document?.file && (
                <Col span={4} className="custom-list-action">
                  <Button
                    onClick={() => viewDocument(document.file, 'show')}
                    type="link"
                  >
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Button>
                  <Button
                    onClick={() => downloadDocument(document.file)}
                    type="link"
                  >
                    <DownloadOutlined style={{ fontSize: iconSize }} />
                  </Button>
                  <Popconfirm
                    title={t('quotations.files.delete.title')}
                    okText={t('quotations.files.delete.ok')}
                    okButtonProps={{ danger: 'true' }}
                    cancelText={t('quotations.files.delete.cancel')}
                    onConfirm={() => deleteFile(document.file._id)}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined style={{ fontSize: iconSize }} />
                  </Popconfirm>
                </Col>
              )}
            </Row>
          ))}
        </Col>
        <Col span={6}>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('quotations.files.drag_or_drop')}
            </p>
          </Dragger>
          {fileList.length > 0 && (
            <Row>
              <Select
                style={{ width: '150px' }}
                onChange={(value) => setFileType(value)}
              >
                {editableDocumentTypes.map((type) => (
                  <Select.Option value={type} key={type}>
                    {t(`quotations.files.${type}`)}
                  </Select.Option>
                ))}
              </Select>
              {fileType && (
                <Button onClick={handlePostDocuments}>
                  {t('quotations.files.save')}
                </Button>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
};

FilesManagement.propTypes = {
  isLoading: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.shape({}))
};

FilesManagement.defaultProps = {
  isLoading: false,
  forceRefresh: undefined,
  setForceRefresh: null,
  documents: null
};

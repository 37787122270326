import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Select } from 'antd';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Custom hook for managing form fields related to service creation or update.
 *
 * @hook
 *
 * @returns {Object} An object containing basicFields and isFieldsLoading.
 */
export const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);

  const getServiceCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'service-categories'
      });
      setCategories(data);
    } catch (e) {
      message(e);
    }
  };

  const getMeasureUnits = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'unit-of-measures'
      });
      setUnits(data);
    } catch (e) {
      message(e);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getMeasureUnits();
    await getServiceCategories();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const basicFields = [
    {
      label: 'nomenclature',
      name: ['nomenclature'],
      rules: [{ required: true }]
    },
    {
      label: 'category',
      name: ['category'],
      input: (
        <Select>
          {categories.map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.title}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'unit',
      name: ['unit'],
      input: (
        <Select>
          {units.map((unit) => (
            <Select.Option key={unit.key} value={unit._id}>
              {unit.title}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      label: 'price',
      name: ['price'],
      input: <InputNumber min={0} />,
      rules: [{ required: true }]
    }
  ];

  return {
    basicFields,
    isFieldsLoading
  };
};

import { useCallback, useEffect, useState } from 'react';
import { Input, Select, Switch, Form, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { VolumeForm } from './VolumeForm';
import { useQuotationContext } from '../../../../contexts/QuotationContext';

const { TextArea } = Input;

export const MaterialShapingForm = ({ form }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const {
    materials,
    handleMaterialChoice,
    globalMaterial,
    materialProvidedByCustomer,
    setMaterialProvidedByCustomer
  } = useQuotationContext();

  const [projectTypes, setProjectTypes] = useState([]);
  const [finishingProducts, setFinishingProducts] = useState([]);

  const getFinishingProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'finishing-products'
      });
      setFinishingProducts(data);
    } catch (e) {
      message(e);
    }
  };

  const getProjectTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'project-types'
      });
      setProjectTypes(data);
    } catch (e) {
      message(e);
    }
  };

  const getOptions = useCallback(async () => {
    await getFinishingProducts();
    await getProjectTypes();
  }, []);

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, [getOptions]);

  return (
    <Form.Item className="quotation-materials-title">
      <Col>
        <Form.Item
          name={['material_configuration', 'material_provided_by_customer']}
          label={t('quotations.form.materials.material_provided_by_customer')}
          initialValue={materialProvidedByCustomer}
        >
          <Switch
            onChange={() =>
              setMaterialProvidedByCustomer(!materialProvidedByCustomer)
            }
          />
        </Form.Item>
        {!materialProvidedByCustomer && (
          <Form.Item
            name={['material_configuration', 'principal_matter']}
            label={t('quotations.form.materials.principal_matter')}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(value) => handleMaterialChoice(value)}
            >
              {(materials || []).map((material) => (
                <Select.Option key={material._id} value={material._id}>
                  {`${material.denomination} ${
                    material.finishing_product
                      ? material.finishing_product.title
                      : ''
                  }`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name={['material_configuration', 'remarks']}
          label={t('quotations.form.materials.remarks')}
        >
          <TextArea />
        </Form.Item>
      </Col>

      {(globalMaterial || materialProvidedByCustomer) && (
        <VolumeForm
          finishingProducts={finishingProducts}
          projectTypes={projectTypes}
          materialProvidedByCustomer={materialProvidedByCustomer}
          form={form}
        />
      )}
    </Form.Item>
  );
};

MaterialShapingForm.propTypes = {
  form: PropTypes.shape({})
};

MaterialShapingForm.defaultProps = {
  form: null
};

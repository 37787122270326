import { useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useQuotationContext } from '../../../../contexts/QuotationContext';
import { useMasterContext } from '../../../../contexts/MasterContext';

const dateFormat = 'DD-MM-YYYY';
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const useGeneralInfosFields = (form) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const {
    VATRates,
    handleVATrate,
    setCheckedList,
    checkedList,
    setGlobalCommission,
    customers,
    setPickedTypeOfWork,
    typeOfWorks,
    setCustomerCoefficient
  } = useQuotationContext();
  const { pickedProject, pickedCustomer, setPickedCustomer } =
    useMasterContext();

  useEffect(() => {
    if (pickedCustomer) {
      setGlobalCommission(pickedCustomer.commission);
      setCustomerCoefficient(pickedCustomer.coefficient);
    }
  }, [pickedCustomer]);

  const [enums, setEnums] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [customerFilter, setCustomerFilter] = useState();
  const [isCheckboxGroupRendered, setIsCheckboxGroupRendered] = useState(false);

  const disabledDate = (current) =>
    current < moment().endOf('day').subtract(1, 'day');

  const customerFetchParameter = () => {
    if (customerFilter) {
      return `?customer=${customerFilter}&`;
    }
    return `?customer=${pickedCustomer._id}&`;
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'quotations/enums'
      });
      setCheckedList(data.type_of_quotation);
      setEnums(data.type_of_quotation);
    } catch (e) {
      message(e);
    }
  };

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `projects${customerFetchParameter()}populate=project_type`
      });
      setProjects(data);
    } catch (e) {
      message(e);
    }
  };

  const getOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    if (customerFilter || pickedCustomer) {
      (async () => {
        await getProjects();
      })();
    }
  }, [customerFilter, pickedCustomer]);

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, [getOptions]);

  const checkboxOptions = enums.map((option) => ({
    label: t(`quotations.form.${option}`),
    value: option
  }));

  const handleCustomerPick = (value) => {
    setPickedCustomer();
    setCustomerFilter(value);
    const foundCustomer = customers.find((customer) => customer._id === value);
    setCustomerCoefficient(foundCustomer.coefficient || 0);
    setGlobalCommission(foundCustomer.commission || 0);

    form.setFieldsValue({
      customer_coefficient: foundCustomer.coefficient,
      global_commission: foundCustomer.commission
    });
  };

  useEffect(() => {
    if (enums && checkedList) setIsCheckboxGroupRendered(true);
  }, [checkedList, enums]);

  const generalInfosFields = [
    {
      name: ['type_of_quotation'],
      initialValue: checkedList,
      input: isCheckboxGroupRendered && (
        <Checkbox.Group
          value={checkedList}
          onChange={(checkedValues) => setCheckedList(checkedValues)}
          options={checkboxOptions}
        />
      )
    },
    {
      name: ['customer'],
      initialValue: pickedCustomer ? pickedCustomer._id : null,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          onChange={(value) => handleCustomerPick(value)}
        >
          {(customers || []).map((customer) => (
            <Select.Option key={customer._id} value={customer._id}>
              {`${customer.first_name} ${customer.last_name}`}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['project'],
      initialValue: pickedProject ? pickedProject._id : null,
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          disabled={!(customerFilter || pickedCustomer)}
        >
          {(projects || []).map((project) => (
            <Select.Option key={project._id} value={project._id}>
              {`${project.denomination || t('projects.form.undefined')}`}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['type_of_work'],
      input: (
        <Select onChange={(value) => setPickedTypeOfWork(value)}>
          {(typeOfWorks || []).map((type) => (
            <Select.Option key={type._id} value={type._id}>
              {type.title}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['validity_date'],
      input: (
        <DatePicker
          format={dateFormat}
          disabledDate={disabledDate}
          style={{
            width: '100%'
          }}
        />
      )
    },
    {
      name: ['previsional_works_start_date'],
      input: (
        <RangePicker
          disabledDate={disabledDate}
          format={dateFormat}
          style={{
            width: '100%'
          }}
        />
      )
    },
    {
      name: ['customer_coefficient'],
      initialValue: pickedCustomer ? pickedCustomer.coefficient : null,
      input: <Input type="number" disabled />
    },
    {
      name: ['global_commission'],
      initialValue: pickedCustomer ? pickedCustomer.commission : null,
      input: (
        <Input
          type="number"
          onChange={(e) => setGlobalCommission(e.target.value)}
        />
      )
    },
    {
      name: ['global_VAT_rate'],
      input: (
        <Select onChange={(value) => handleVATrate(value)}>
          {(VATRates || []).map((rate) => (
            <Select.Option key={rate._id} value={rate._id}>
              {`${rate.percentage}%`}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['description'],
      input: <TextArea />
    },
    {
      name: ['remarks'],
      input: <TextArea />
    }
  ];

  return {
    generalInfosFields,
    isFieldsLoading,
    checkedList
  };
};

import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuotationContext } from '../../../../contexts/QuotationContext';

const { TextArea } = Input;
const { Option } = Select;

/**
 * ServiceItem component is used to render and manage service items in a quotation form.
 *
 * @component
 * @param {object} props - The component's props.
 * @param {FormInstance} props.form - Ant Design form instance for managing form fields.
 * @param {number} props.name - The index of the service item within the form.
 * @param {FieldData} props.restField - Rest field data used in the Ant Design form.
 * @param {Function} props.remove - Function to remove the service item.
 * @returns {JSX.Element} - Rendered ServiceItem component.
 */
export const ServiceItem = ({
  form,
  name,
  restField,
  remove,
  calculateTotals
}) => {
  const { t } = useTranslation();
  const { VATRates, selectedRate, globalCommission, services, volumesArray } =
    useQuotationContext();
  const [isSurfaceOrQuantity, setIsSurfaceOrQuantity] = useState('surface');

  useEffect(() => {
    let linkedVolumes = [];
    if (form) {
      linkedVolumes = form.getFieldValue(['services', name, 'linked_volumes']);

      if (linkedVolumes && isSurfaceOrQuantity === 'surface') {
        const totalSurface = linkedVolumes.reduce((total, index) => {
          const volume = volumesArray[index];
          return total + (volume && volume.surface ? volume.surface : 0);
        }, 0);

        form.setFields([
          {
            name: ['services', name, 'quantity_surface'],
            value: Number(totalSurface.toFixed(2))
          }
        ]);

        calculateTotals();
      }
    }
  }, [volumesArray, form, name]);

  const booleanOptions = [
    {
      label: t('quotations.form.services.isBase'),
      boolean: false
    },
    {
      label: t('quotations.form.services.isOption'),
      boolean: true
    }
  ];

  const selectAfter = (
    <Form.Item
      name={[name, 'quantity_surface_type']}
      initialValue="surface"
      noStyle
    >
      <Select
        defaultValue="surface"
        onChange={(value) => setIsSurfaceOrQuantity(value)}
      >
        <Option value="surface">
          {t('quotations.form.services.surface_option')}
        </Option>
        <Option value="quantity">
          {t('quotations.form.services.quantity_option')}
        </Option>
      </Select>
    </Form.Item>
  );

  return (
    <Row
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <span>{`${t('quotations.form.services.item')} ${name + 1}`}</span>
        </Col>
        <Col>
          <Form.Item name={[name, 'is_option']} valuePropName="select">
            <Select
              placeholder={t('quotations.form.services.isOption')}
              style={{ width: '150px' }}
            >
              {booleanOptions.map((option) => (
                <Select.Option value={option.boolean} key={option.boolean}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <CloseOutlined onClick={() => remove(name)} />
        </Col>
      </Row>
      <Col>
        <Row style={{ flexDirection: 'column' }}>
          <Form.Item
            name={[name, 'services_catalog']}
            label={t('quotations.form.services.services_catalog')}
            {...restField}
          >
            <Select showSearch optionFilterProp="children">
              {(services || []).map((service) => (
                <Select.Option key={service._id} value={service._id}>
                  {`${service.nomenclature}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[name, 'linked_volumes']}
            label={t('quotations.form.services.linked_volumes')}
            {...restField}
          >
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              allowClear
            >
              {(volumesArray || []).map((volume, index) => (
                <Select.Option key={volume.id} value={index}>
                  {`Volume ${index}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[name, 'quantity_surface']}
            label={t('quotations.form.services.quantity_surface')}
            {...restField}
          >
            <InputNumber
              addonAfter={selectAfter}
              disabled={isSurfaceOrQuantity === 'surface'}
            />
          </Form.Item>
          <Form.Item
            name={[name, 'unprocessed_value']}
            label={t('quotations.form.materials.unprocessed_value')}
          >
            <Input type="number" disabled />
          </Form.Item>
          <Form.Item
            name={[name, 'customer_coefficient_value']}
            label={t('quotations.form.materials.customer_coefficient_value')}
          >
            <Input type="number" disabled />
          </Form.Item>
          <Form.Item
            name={[name, 'commission']}
            label={t('quotations.form.services.commission')}
            initialValue={globalCommission}
          >
            <Input type="number" initialValue={globalCommission} />
          </Form.Item>
          <Form.Item
            name={[name, 'facial_value']}
            label={t('quotations.form.materials.facial_value')}
          >
            <Input type="number" disabled />
          </Form.Item>
          <Form.Item
            name={[name, 'VAT_rate']}
            label={t('quotations.form.services.VAT_rate')}
            initialValue={selectedRate?._id}
          >
            <Select>
              {(VATRates || []).map((rate) => (
                <Select.Option key={rate._id} value={rate._id}>
                  {`${rate.title}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[name, 'all_included_value']}
            label={t('quotations.form.materials.all_included_value')}
          >
            <Input type="number" disabled />
          </Form.Item>
          <Form.Item
            name={[name, 'remarks']}
            label={t('quotations.form.services.remarks')}
            {...restField}
          >
            <TextArea />
          </Form.Item>
        </Row>
      </Col>
    </Row>
  );
};

ServiceItem.propTypes = {
  name: PropTypes.number.isRequired,
  restField: PropTypes.shape({}),
  remove: PropTypes.func,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFields: PropTypes.func
  }),
  calculateTotals: PropTypes.func
};

ServiceItem.defaultProps = {
  form: null,
  restField: null,
  remove: null,
  calculateTotals: null
};

export const createFieldsArray = (fields) => {
  const result = {};
  fields.forEach((item) => {
    const number = item.name.filter((x) => typeof x === 'number')[0];
    const string = item.name.filter((x) => typeof x === 'string').slice(-1)[0];
    if (result[number]) {
      result[number][string] = item.value;
    } else {
      result[number] = { [string]: item.value };
    }
  });
  return Object.values(result);
};

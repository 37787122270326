import React from 'react';
import useColumns from './columns';
import { ListResource } from '../../../components/ListResource/ListResource';
import { ExtraFilters } from '../ExtraFilters';
import { useAccessoryContext } from '../../../contexts/AccessoryContext';
import { DownloadFileButtons } from '../../../components/DownloadFileButton/DownloadFileButton';
import { headers } from './formatter/headers';

/**
 * Renders a component for managing accessories with a list view.
 *
 * @component
 * @returns {JSX.Element} The rendered Accessories component.
 */
export const Accessories = () => {
  const columns = useColumns();
  const { importTemplate } = useAccessoryContext();

  return (
    <ListResource
      columns={columns}
      withArchiveButton
      withImportButton
      extraFilters={
        <ExtraFilters
          resourceModelName="accessory"
          translationName="accessories"
          extraAccessoriesFilters
        />
      }
      populate="category,supplier"
      resourceName="accessories"
      resourceModelName="Accessory"
      headers={headers}
      extraMenu={
        importTemplate &&
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
    />
  );
};

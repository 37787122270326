import PropTypes from 'prop-types';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Renders a component for creating or updating customer information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The rendered CreateUpdateCustomer component.
 */
const CreateUpdateCustomer = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="customers"
      resource="customers"
    />
  );
};

CreateUpdateCustomer.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCustomer;

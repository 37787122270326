import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CategoryFilter } from './CategoryFilter';

/**
 * @component
 * Component for rendering extra filters for resource listings.
 * @param {object} props - The component props.
 * @param {string} props.resourceModelName - The name of the resource model for fetching filters.
 * @param {string} props.translationName - The name used for translation keys.
 * @param {boolean} [props.extraMaterialFilters=false] - Flag indicating if extra material filters should be displayed.
 * @param {boolean} [props.extraAccessoriesFilters=false] - Flag indicating if extra accessories filters should be displayed.
 * @returns {JSX.Element} The JSX element representing the ExtraFilters component.
 */
export const ExtraFilters = ({
  resourceModelName,
  translationName,
  extraMaterialFilters,
  extraAccessoriesFilters
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [materials, setMaterials] = useState([]);
  const [finishingProducts, setFinishingProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const fetchMaterials = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `materials?status=ACTIVE&populate=finishing_product,category`
      });
      setMaterials(data);
    } catch (e) {
      message(e);
    }
  };

  const fetchFinishingProducts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'finishing-products'
      });
      setFinishingProducts(data);
    } catch (e) {
      message(e);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'suppliers'
      });
      setSuppliers(data);
    } catch (e) {
      message(e);
    }
  };

  const fetchSelectOptions = async () => {
    if (extraMaterialFilters) {
      await fetchMaterials();
      await fetchFinishingProducts();
    }
    if (extraAccessoriesFilters) {
      await fetchSuppliers();
    }
  };

  useEffect(() => {
    (async () => {
      await fetchSelectOptions();
    })();
  }, []);

  const filteredData = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `&k=${value}`
      });
    } else {
      navigate({
        pathname
      });
    }
  };

  return (
    <Col
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Col>{t('materials.filters.filters')}</Col>
      <Col>
        <CategoryFilter
          resourceModelName={resourceModelName}
          translationName={translationName}
          filteredData={filteredData}
        />
      </Col>
      {extraMaterialFilters && (
        <>
          <Col>
            <Select
              allowClear
              placeholder={t('materials.filters.denomination')}
              style={{ width: 150 }}
              onSelect={(_, { type, value }) =>
                filteredData(`${type}=${value}`)
              }
              optionFilterProp="children"
              showSearch
              onClear={() => filteredData('')}
            >
              {materials.map((material) => (
                <Select.Option key={material._id} value={material.denomination}>
                  {material.denomination}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              allowClear
              placeholder={t('materials.filters.finishing_product')}
              style={{ width: 150 }}
              onSelect={(_, { type, value }) =>
                filteredData(`${type}=${value}`)
              }
              optionFilterProp="children"
              showSearch
              onClear={() => filteredData('')}
            >
              <Select.Option value="">
                {t('materials.filters.reset')}
              </Select.Option>
              {finishingProducts.map((finishingProduct) => (
                <Select.Option
                  key={finishingProduct._id}
                  value={finishingProduct.title}
                >
                  {finishingProduct.title}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </>
      )}
      {extraAccessoriesFilters && (
        <Col>
          <Select
            allowClear
            placeholder={t('accessories.filters.suppliers')}
            style={{ width: 150 }}
            onSelect={(value) => filteredData(value)}
            optionFilterProp="children"
            showSearch
            onClear={() => filteredData('')}
          >
            {suppliers.map((supplier) => (
              <Select.Option key={supplier._id} value={supplier.denomination}>
                {supplier.denomination}
              </Select.Option>
            ))}
          </Select>
        </Col>
      )}
    </Col>
  );
};

ExtraFilters.propTypes = {
  resourceModelName: PropTypes.string.isRequired,
  translationName: PropTypes.string.isRequired,
  extraMaterialFilters: PropTypes.bool,
  extraAccessoriesFilters: PropTypes.bool
};

ExtraFilters.defaultProps = {
  extraMaterialFilters: false,
  extraAccessoriesFilters: false
};

import React, { useEffect, useState } from 'react';
import { Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  projectsStatus,
  quotationStatus
} from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Custom hook for generating columns configuration for projects and quotations tables.
 *
 * @hook
 *
 * @returns {Object} - An object containing the column configurations for projects and quotations tables.
 */
const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);

  const getCustomers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'customers'
      });
      setCustomers(data);
    } catch (e) {
      message(e);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'users'
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomers();
      await getUsers();
    })();
  }, []);

  const basicColumns = [
    {
      title: t('projects.table.customer'),
      dataIndex: 'customer',
      key: 'customer',
      sorter: (a, b) => {
        const nameA =
          `${a.customer.first_name} ${a.customer.last_name}`.toLowerCase();
        const nameB =
          `${b.customer.first_name} ${b.customer.last_name}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filters: customers.map((customer) => ({
        text: `${`${customer.first_name} ${customer.last_name}` || ''}`,
        value: customer._id
      })),
      render: (customer) =>
        (customer && `${customer.first_name} ${customer.last_name}`) ||
        t('errors.form.not_specified')
    },
    {
      title: t('projects.table.address'),
      dataIndex: 'site_address',
      key: 'site_address',
      sorter: true,
      render: (address) =>
        address && (
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <Row>{address.street}</Row>
            <Row>{`${address.postal_code} ${address.city}`}</Row>
            {address.additional ? <Row> {address.additional}</Row> : null}
          </Row>
        )
    },
    {
      title: t('projects.table.dwelling_type'),
      dataIndex: 'dwelling_type',
      key: 'dwelling_type',
      sorter: true
    },
    {
      title: t('projects.table.project_type'),
      dataIndex: 'project_type',
      key: 'project_type',
      sorter: true,
      render: (type) => type && type.title
    },
    {
      title: t('projects.table.MP_supervisor'),
      dataIndex: 'MP_supervisor',
      key: 'MP_supervisor',
      filters: users.map((user) => ({
        text: `${`${user.first_name} ${user.last_name}` || ''}`,
        value: user._id
      })),
      render: (MP_supervisor) =>
        MP_supervisor &&
        `${MP_supervisor.first_name} ${MP_supervisor.last_name}`
    },
    {
      title: t('projects.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={projectsStatus[status]}>{t(`projects.form.${status}`)}</Tag>
      )
    }
  ];

  const quotationsColumns = [
    {
      title: t('projects.table.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true,
      render: (number) => <Tag>{number}</Tag> || t('errors.form.not_specified')
    },
    {
      title: t('projects.table.status'),
      render: (record) => (
        <Tag
          color={
            quotationStatus[
              record.kanban_status ? record.kanban_status.title : record.status
            ]
          }
        >
          {record.kanban_status
            ? record.kanban_status.title
            : t(`quotations.form.${record.status}`)}
        </Tag>
      )
    },
    {
      title: t('projects.table.creation_date'),
      dataIndex: 'creation_date',
      key: 'creation_date',
      sorter: true,
      render: (date) =>
        (date && moment(date).format('DD-MM-YYYY')) ||
        t('errors.form.not_specified')
    },
    {
      title: t('projects.table.amount'),
      dataIndex: 'summary',
      sorter: true,
      render: (summary) =>
        (summary && `${summary?.base_total?.all_included_total}€`) ||
        t('errors.form.not_specified')
    }
  ];

  return {
    basicColumns,
    quotationsColumns
  };
};

export default useColumns;

import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * @component
 * Component for rendering a category filter dropdown.
 * @param {object} props - The component props.
 * @param {string} props.resourceModelName - The name of the resource model to fetch categories for.
 * @param {string} props.translationName - The name used for translation keys.
 * @param {Function} props.filteredData - The callback function to handle filtered data.
 * @returns {JSX.Element} The JSX element representing the CategoryFilter component.
 */
export const CategoryFilter = ({
  resourceModelName,
  translationName,
  filteredData
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceModelName}-categories`
      });
      setCategories(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchCategories();
    })();
  }, []);

  return (
    <Select
      allowClear
      placeholder={t(`${translationName}.filters.category`)}
      style={{ width: 150 }}
      onSelect={(_, { type, value }) => filteredData(`${type}=${value}`)}
      optionFilterProp="children"
      showSearch
      onClear={() => filteredData('')}
    >
      {categories.map((category) => (
        <Select.Option key={category._id} value={category.title}>
          {category.title}
        </Select.Option>
      ))}
    </Select>
  );
};

CategoryFilter.propTypes = {
  resourceModelName: PropTypes.string.isRequired,
  translationName: PropTypes.string.isRequired,
  filteredData: PropTypes.func
};

CategoryFilter.defaultProps = {
  filteredData: null
};

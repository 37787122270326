/**
 * Calculates and updates values related to invoices and prices in a form.
 *
 * @param {Object} e - The input data object.
 * @param {string} selectedType - The selected type of the item.
 * @param {Object} form - The form object to update fields.
 * @param {function} t - The translation function for localization.
 *
 * @returns {void}
 */
export const calculateItemValues = (e, selectedType, form, t) => {
  if (e.unit_price && e.quantity) {
    const VAT_excluded_price = e.unit_price * e.quantity;
    if (selectedType === t('invoices.form.extra_addition')) {
      form.setFields([
        {
          name: ['VAT_excluded_price'],
          value: VAT_excluded_price
        }
      ]);
    } else {
      form.setFields([
        {
          name: ['VAT_excluded_price'],
          value: VAT_excluded_price
        }
      ]);
    }

    if (
      e.VAT_rate &&
      e.VAT_rate.percentage !== undefined &&
      selectedType === t('invoices.form.extra_addition')
    ) {
      const VAT_price = VAT_excluded_price * (e.VAT_rate.percentage / 100);
      const all_included_value =
        VAT_excluded_price * (1 + e.VAT_rate.percentage / 100);
      form.setFields([
        {
          name: ['VAT_rate', 'value'],
          value: VAT_price
        },
        {
          name: ['all_included_value'],
          value: all_included_value
        }
      ]);
    }
  }
};

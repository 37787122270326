import { useTranslation } from 'react-i18next';

/**
 * Custom hook for defining table columns to display accessory data.
 *
 * @hook
 * @returns {Array} An array of column objects.
 */
const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('accessories.form.nomenclature'),
      dataIndex: 'nomenclature',
      key: 'nomenclature',
      sorter: (a, b) => a?.nomenclature.localeCompare(b?.nomenclature)
    },
    {
      title: t('accessories.form.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => category.title,
      sorter: (a, b) => a?.category.title.localeCompare(b?.category.title)
    },
    {
      title: t('accessories.form.price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => (a.price < b.price ? -1 : 1)
    },
    {
      title: t('accessories.form.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier) => supplier.denomination,
      sorter: (a, b) =>
        a?.supplier.denomination.localeCompare(b?.supplier.denomination)
    }
  ];
};

export default useColumns;

import { useTranslation } from 'react-i18next';

/**
 * Hook that generates the content for displaying order totals in a list.
 *
 * @hook
 * @param {object} datas - Totals data object.
 * @returns {Array} Array of objects containing label and content for each total field.
 */
export const useTotalsListContent = (datas = {}) => {
  const { t } = useTranslation();
  const { totalHT, totalTva, totalTTC, payed, remaining } = datas;

  return [
    {
      label: t('orders.show.totalHT'),
      content:
        (totalHT && `${totalHT.toFixed(2)}€`) || t('errors.form.not_specified')
    },
    {
      label: t('orders.show.totalTva'),
      content:
        (totalTva && `${totalTva.toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('orders.show.totalTTC'),
      content:
        (totalTTC && `${totalTTC.toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('orders.show.payed'),
      content:
        (payed !== null && payed !== undefined && `${payed.toFixed(2)}€`) ||
        t('errors.form.not_specified')
    },
    {
      label: t('orders.show.remaining'),
      content:
        (remaining && `${remaining.toFixed(2)}€`) ||
        t('errors.form.not_specified')
    }
  ];
};

import { useTranslation } from 'react-i18next';

/**
 * useAccessoriesColumns is a custom hook that returns an array of column configurations for displaying accessory-related data.
 *
 * @hook
 *
 * @returns {Object[]} Array of column configurations.
 */
export const useAccessoriesColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('quotations.table.accessory'),
      key: 'is_option',
      dataIndex: 'is_option',
      render: (boolean) =>
        boolean ? t('quotations.table.option') : t('quotations.table.base')
    },
    {
      title: t('quotations.table.accessory_catalog'),
      key: 'accessory_catalog',
      dataIndex: 'accessory_catalog',
      render: (accessory) => accessory && accessory.nomenclature
    },
    {
      title: t('quotations.table.quantity'),
      key: 'quantity',
      dataIndex: 'quantity'
    },
    {
      title: t('quotations.table.facial_value'),
      key: 'facial_value',
      dataIndex: 'facial_value',
      render: (value) => value && `${value}€`
    },
    {
      title: t('quotations.table.VAT_rate'),
      key: 'VAT_rate',
      dataIndex: 'VAT_rate',
      render: (rate) => rate && `${rate.percentage}%`
    },
    {
      title: t('quotations.table.all_included_value'),
      key: 'all_included_value',
      dataIndex: 'all_included_value',
      render: (value) => value && `${value.toFixed(2)}€`
    }
  ];
};

import React from 'react';
import useColumns from './columns';
import { ListResource } from '../../../components/ListResource/ListResource';
import { ExtraFilters } from '../ExtraFilters';
import { useShapingContext } from '../../../contexts/ShapingContext';
import { DownloadFileButtons } from '../../../components/DownloadFileButton/DownloadFileButton';
import { headers } from './formatter/headers';

/**
 * @component
 * Component for displaying a list of shapings with filter and archive functionality.
 * @returns {JSX.Element} The JSX element representing the Shapings component.
 */
export const Shapings = () => {
  const columns = useColumns();
  const { importTemplate } = useShapingContext();

  return (
    <ListResource
      columns={columns}
      populate="category,unit"
      resourceName="shapings"
      resourceModelName="Shaping"
      withArchiveButton
      withImportButton
      headers={headers}
      extraMenu={
        importTemplate &&
        importTemplate[0]?.file && [
          {
            key: 'template',
            label: importTemplate[0]?.file && (
              <DownloadFileButtons file={importTemplate[0]?.file} />
            )
          }
        ]
      }
      extraFilters={
        <ExtraFilters resourceModelName="shaping" translationName="shapings" />
      }
    />
  );
};

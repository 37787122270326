import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Row, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useCustomerFields from './fields/customerFields';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import { routes } from '../../../utils/constants/adminRoutes';
import useInfosFields from './fields/infosFields';
import { useMasterContext } from '../../../contexts/MasterContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import useInvoiceBodyFields from './fields/invoiceBodyFields';
import { useTotalFields } from './fields/totalFields';
import { InvoiceTable } from '../InvoiceTable';
import { generateTableDataSource } from './utils/generateTableDataSource';
import { setTotalFields } from './utils/setTotalFields';

/**
 * Component for creating or updating invoices.
 *
 * @component
 *
 *  @returns {JSX.Element} - ExtraButtons component.
 */
const CreateInvoice = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { resetMasterContext, pickedOrder, pickedCustomer } =
    useMasterContext();
  const { t } = useTranslation();
  const { source } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const generateFields = useGenerateFormItem();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { infosFields, isLoading } = useInfosFields();
  const customerFields = useCustomerFields();
  const totalFields = useTotalFields(source);
  const [itemData, setItemData] = useState();
  const [quotation, setQuotation] = useState();
  const { invoiceBodyFields } = useInvoiceBodyFields();
  const [paid, setPaid] = useState();

  const createResource = async (body) => {
    setIsSubmitting(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `invoices`,
        body: {
          ...body,
          order: pickedOrder._id,
          customer: pickedCustomer._id,
          details: source === 'BASIC' ? itemData : [],
          totals:
            source === 'BASIC'
              ? body.totals
              : {
                  advance_total: body.totals.advance_total,
                  due: body.totals.advance_total,
                  paid: 0
                }
        }
      });
      navigate(`${routes.INVOICES}/show/${data._id}`);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getQuotation = async () => {
    const activeQuotation =
      pickedOrder &&
      pickedOrder.quotations.find(
        (quotationItem) => quotationItem.status === 'ACTIVE'
      ).quotation;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quotations/show/${activeQuotation._id}?populate=global_VAT_rate`
      });
      const dataSource = generateTableDataSource(data, t);
      setItemData(dataSource);
      setQuotation(data);
    } catch (e) {
      message(e);
    }
  };

  const getPaid = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `invoices/paid/${pickedOrder._id}`
      });
      setPaid(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (pickedOrder) {
      (async () => {
        await getQuotation();
        await getPaid();
      })();
    }
  }, [pickedOrder]);

  const handleSubmit = async (values) => {
    resetMasterContext();
    await createResource(values);
  };

  useEffect(() => {
    if (itemData && quotation) {
      setTotalFields(quotation, itemData, paid, t, form);
    }
  }, [itemData, quotation, paid]);

  return (
    <>
      <PageHeaderCustom title={t(`invoices.form.title.${source}`)} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
          >
            <Card
              title={t('invoices.form.invoices_infos')}
              style={{ marginBottom: 16 }}
            >
              {infosFields.map((field) => generateFields('invoices', field))}
            </Card>
            <Card
              title={t('invoices.form.customer_infos')}
              style={{ marginBottom: 16 }}
            >
              {customerFields.map((field) => generateFields('invoices', field))}
            </Card>
            <Card
              title={t('invoices.form.invoice_body_infos')}
              style={{ marginBottom: 16 }}
            >
              {invoiceBodyFields.map((field) =>
                generateFields('invoices', field)
              )}
            </Card>
            {source === 'BASIC' && (
              <Card
                title={t('invoices.form.order_to_bill')}
                style={{ marginBottom: 16 }}
              >
                <InvoiceTable
                  itemData={itemData}
                  setItemData={setItemData}
                  purpose="form"
                />
              </Card>
            )}
            <Card
              title={t('invoices.form.total_infos')}
              style={{ marginBottom: 16 }}
            >
              {totalFields.map((field) => generateFields('invoices', field))}
            </Card>
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(`${routes.INVOICES}`)}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit" loading={isSubmitting}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

export default CreateInvoice;

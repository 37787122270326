export const headers = [
  {
    label: 'customer',
    key: 'full_name',
    display: true,
    required: false
  },
  {
    label: 'email',
    key: 'customer.email',
    display: true,
    required: true
  },
  {
    label: 'rooms',
    key: 'all_rooms',
    display: true,
    required: true
  },
  {
    label: 'site_address.street',
    key: 'site_address.street',
    display: true,
    required: true
  },
  {
    label: 'site_address.postal_code',
    key: 'site_address.postal_code',
    display: true,
    required: true
  },
  {
    label: 'site_address.city',
    key: 'site_address.city',
    display: true,
    required: true
  },
  {
    label: 'site_address.additional',
    key: 'site_address.additional',
    display: true,
    required: false
  },
  {
    label: 'site_access',
    key: 'site_access',
    display: true,
    required: true
  },
  {
    label: 'dwelling_type',
    key: 'dwelling_type',
    display: true,
    required: false
  },
  {
    label: 'project_type',
    key: 'project_type.title',
    display: true,
    required: false
  },
  {
    label: 'MP_supervisor',
    key: 'MP_supervisor_full_name',
    display: true,
    required: true
  },
  {
    label: 'mp_supervisor_email',
    key: 'MP_supervisor.email',
    display: true,
    required: true
  }
];

import {
  CheckOutlined,
  ContainerOutlined,
  FileOutlined,
  UploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const iconSize = 18;

/**
 * Component that renders extra buttons for invoice actions.
 *
 * @component
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - State to determine Modal opening.
 * @param {function} props.setOpen - Function to set open state.
 * @param {function} props.patchInvoiceStatus - Function to update invoice status.
 * @param {string} props.invoiceType - Type of the invoice.
 * @param {boolean} props.creditEdition - Boolean to display credit edition.
 * @param {function} props.setCreditEdition - Function to set creditEdition.
 * @returns {JSX.Element} - ExtraButtons component.
 */
export const ExtraButtons = ({
  open,
  setOpen,
  patchInvoiceStatus,
  invoiceType,
  creditEdition,
  setCreditEdition,
  invoiceStatus,
  invoiceFile
}) => {
  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();
  return (
    <Space>
      <Button icon={<CheckOutlined />} onClick={() => setOpen(!open)}>
        {t('invoices.show.paid')}
      </Button>
      <Button
        icon={<UploadOutlined />}
        onClick={() => downloadDocument(invoiceFile)}
      >
        {t('invoices.show.upload')}
      </Button>
      {invoiceType === 'BASIC' && (
        <Button
          icon={<FileOutlined />}
          onClick={() => setCreditEdition(!creditEdition)}
        >
          {t('invoices.show.credit_note')}
        </Button>
      )}
      {invoiceStatus === 'PENDING' && (
        <Popconfirm
          title={t('datatable.column.action.archive.title')}
          okText={t('datatable.column.action.archive.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.archive.cancel')}
          onConfirm={() => patchInvoiceStatus('ARCHIVED')}
          icon={<WarningOutlined />}
        >
          <Button
            danger
            icon={
              <ContainerOutlined style={{ fontSize: iconSize }} type="delete" />
            }
          >
            {t('buttons.archive')}
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
};

ExtraButtons.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  patchInvoiceStatus: PropTypes.func,
  invoiceType: PropTypes.string,
  creditEdition: PropTypes.bool,
  setCreditEdition: PropTypes.func,
  invoiceStatus: PropTypes.string,
  invoiceFile: PropTypes.shape({})
};

ExtraButtons.defaultProps = {
  open: false,
  setOpen: null,
  patchInvoiceStatus: null,
  invoiceType: undefined,
  creditEdition: false,
  setCreditEdition: null,
  invoiceStatus: undefined,
  invoiceFile: null
};

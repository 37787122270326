import { Form, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dragger from 'antd/lib/upload/Dragger';
import { FileOutlined } from '@ant-design/icons';
import { useQuotationContext } from '../../../../contexts/QuotationContext';

export const PriceConditionsForm = ({ form }) => {
  const { t } = useTranslation();
  const { selectedRate, fileList, setFileList, discount } =
    useQuotationContext();

  const draggerProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return true;
    },
    fileList
  };

  const handleDiscountType = (value) => {
    if (value === '%') {
      form.setFieldsValue({
        'summary.price_conditions.discount.type': value
      });
    } else {
      form.setFieldsValue({
        'summary.price_conditions.discount.type': value
      });
    }
  };

  const selectBefore = (
    <Form.Item
      name={['summary', 'price_conditions', 'discount', 'type']}
      initialValue={discount?.type || '%'}
      noStyle
    >
      <Select
        className="quotation-price_conditions-select-before"
        onChange={handleDiscountType}
      >
        <Select.Option value="percentage">%</Select.Option>
        <Select.Option value="monetary">€</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form.Item className="quotation-price_conditions-title">
      <Form.Item
        name={['summary', 'price_conditions', 'discount', 'amount']}
        label={t('quotations.form.summary.price_conditions.discount')}
        initialValue={discount?.amount}
      >
        <Input type="number" addonBefore={selectBefore} />
      </Form.Item>

      {selectedRate && selectedRate.percentage >= 10 ? (
        <Form.Item
          name={['summary', 'price_conditions', 'VAT_attestation']}
          label={t('quotations.form.summary.price_conditions.VAT_attestation')}
        >
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <FileOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
        </Form.Item>
      ) : null}
    </Form.Item>
  );
};

PriceConditionsForm.propTypes = {
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  })
};

PriceConditionsForm.defaultProps = {
  discounts: null,
  form: null
};

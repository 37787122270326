import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Button, Collapse, Form, Row, Spin, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import {
  drawerFormLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import { useGeneralInfosFields } from './GeneralInfos/generalInfosFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { MaterialShapingForm } from './Material/MaterialShapingsForm';
import { ServicesForm } from './Services/ServicesForm';
import { AccessoriesForm } from './Accessories/AccessoriesForm';
import { SummaryForm } from './Summary/SummaryForm';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { quotationCalculations } from '../QuotationCalculations/quotationCalculations';
import { useMasterContext } from '../../../contexts/MasterContext';
import { QuotationDrawer } from './QuotationDrawer';
import { routes } from '../../../utils/constants/adminRoutes';
import { createFieldsArray } from '../QuotationCalculations/utils/createFieldsArray';
import { LoadingModal } from './LoadingModal';

const { Panel } = Collapse;

/**
 * CreateUpdateQuotation is a component for creating or updating a quotation.
 *
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the quotation, either 'create' or 'edit'.
 * @returns {JSX.Element} - Rendered CreateUpdateQuotation component.
 */
export const CreateUpdateQuotation = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { generalInfosFields, isFieldsLoading, checkedList } =
    useGeneralInfosFields(form);
  const {
    setTotals,
    VATRates,
    accessories,
    services,
    materials,
    shapings,
    materialProvidedByCustomer,
    customerCoefficient,
    fileList,
    setFileList,
    setGlobalMaterial,
    setCheckedList,
    setVolumesArray,
    setGlobalCommission,
    setDiscount,
    setCustomerCoefficient
  } = useQuotationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const { setPickedCustomer, setPickedProject } = useMasterContext();

  const showDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        setDiscount(data.summary.price_conditions.discount);
        setGlobalCommission(data.customer.commission);
        setPickedCustomer(data.customer);
        setCustomerCoefficient(data.customer.coefficient);
        setPickedProject(data.project);
        setGlobalMaterial(data.material_configuration.principal_matter);
        setVolumesArray(data.materials);
        return {
          ...data,
          customer: data.customer._id,
          customer_coefficient: data.customer.coefficient,
          project: data.project._id,
          date_of_birth: data.creation_date && moment(data.creation_date),
          validity_date: data.validity_date && moment(data.validity_date),
          previsional_works_start_date:
            data.previsional_works_start_date &&
            moment(data.previsional_works_start_date)
        };
      }
    }
  };

  const updateQuotation = async (body) => {
    setIsSubmitting(true);
    const formData = new FormData();
    if (fileList.length) {
      fileList.forEach((file) => {
        formData.append('VAT_attestation', file);
      });
    }

    formData.append(
      'values',
      JSON.stringify({
        ...body,
        locale: moment.locale()
      })
    );
    setOpen(!open);
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `quotations/${id}`,
        body: formData
      });
      setFileList([]);
      setOpen(!open);

      navigate(`${routes.QUOTATIONS}/show/${data._id}`);
    } catch (e) {
      setOpen(!open);
      setIsSubmitting(false);
      message(e);
    }
  };

  useEffect(
    () => () => {
      setPickedCustomer();
      setPickedProject();
    },
    []
  );

  const calculateTotals = () => {
    const fieldsValues = form.getFieldsValue();
    const rawTotals = quotationCalculations(
      fieldsValues,
      accessories,
      VATRates,
      form,
      services,
      materials,
      shapings,
      materialProvidedByCustomer,
      customerCoefficient
    );
    return setTotals(rawTotals);
  };

  const createQuotation = async (body) => {
    setIsSubmitting(true);

    const formData = new FormData();

    if (fileList.length) {
      fileList.forEach((file) => {
        formData.append('VAT_attestation', file);
      });
    }

    formData.append(
      'values',
      JSON.stringify({
        ...body,
        locale: moment.locale()
      })
    );
    setOpen(!open);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `quotations`,
        body: formData
      });
      setFileList([]);
      setOpen(!open);
      navigate(`${routes.QUOTATIONS}/show/${data._id}`);
    } catch (e) {
      setOpen(!open);
      setIsSubmitting(false);
      message(e);
    }
  };

  const getQuotation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quotations/${id}?populate=customer,project`
      });
      form.setFieldsValue(config.onGetResource.setFields(data));
      setCheckedList(data.type_of_quotation);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id, isFieldsLoading]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);

      (async () => {
        await getQuotation();
      })();
    }
  }, [getQuotation]);

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateQuotation(values);
    if (purpose === 'create') await createQuotation(values);
  };

  const handleOnFieldsChange = (fields) => {
    const materialsFields = fields.filter((field) =>
      field.name.includes('materials')
    );
    const rawVolumesArray = createFieldsArray(materialsFields);
    const volumesArrayToBeSet = rawVolumesArray.slice(0, -1);
    setVolumesArray(volumesArrayToBeSet);
    calculateTotals();
  };

  return (
    <>
      <PageHeaderCustom title={t(`quotations.form.title.${purpose}`)} />
      <Spin spinning={isLoading}>
        <ContentCustom>
          <Form
            {...drawerFormLayout}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            onFieldsChange={(_, allFields) => handleOnFieldsChange(allFields)}
          >
            <Card
              title={t('quotations.form.general_infos')}
              style={{ marginBottom: 8 }}
            >
              <Form.Item className="custom-form-item-quotations-general-infos">
                {generalInfosFields.map((field) =>
                  generateFields('quotations', field)
                )}
              </Form.Item>
            </Card>
            <Collapse>
              {checkedList?.includes('SHAPING-MATERIALS') && (
                <Panel key="1" header={t('quotations.form.materials.title')}>
                  <MaterialShapingForm form={form} />
                </Panel>
              )}
              {checkedList?.includes('SERVICES') && (
                <Panel key="2" header={t('quotations.form.services.title')}>
                  <ServicesForm form={form} calculateTotals={calculateTotals} />
                </Panel>
              )}
              {checkedList?.includes('ACCESSORIES') && (
                <Panel key="3" header={t('quotations.form.accessories.title')}>
                  <AccessoriesForm form={form} />
                </Panel>
              )}
            </Collapse>
            <Card
              title={t('quotations.form.summary.title')}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <SummaryForm form={form} />
            </Card>
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit" loading={isSubmitting}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
            <QuotationDrawer
              showDrawer={showDrawer}
              openDrawer={openDrawer}
              form={form}
            />
          </Form>
        </ContentCustom>
      </Spin>
      <LoadingModal open={open} />
    </>
  );
};

CreateUpdateQuotation.propTypes = {
  purpose: PropTypes.string.isRequired
};

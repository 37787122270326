import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Divider } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { DescriptionList } from '../../../components/DescriptionList/DescriptionList';
import { useCustomerListContent } from './customerListContent';
import { ContentCustom } from '../../../components';
import { useGeneralInfosListContent } from './generalInfosListContent';
import { useTotalColumns } from './Columns/totalsColumns';
import { FilesManagement } from './FilesManagement';
import { ExtraButtons } from './ExtraButtons';
import { useDocuments } from './useDocuments';
import { QuotationDetailsTables } from './Tables/QuotationDetailsTable';
import { QuotationsSteps } from './QuotationsSteps';

/**
 * ShowQuotation component displays the details of a quotation, including customer information,
 * general details, totals, attached documents, and related tables.
 *
 * @component
 * @returns {JSX.Element} Rendered JSX element representing the ShowQuotation component.
 */
export const ShowQuotation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [quotation, setQuotation] = useState();
  const [kanbanStatuses, setKanbanStatuses] = useState();

  const customerFields = useCustomerListContent(quotation);
  const generalInfosFields = useGeneralInfosListContent(quotation);
  const totalColumns = useTotalColumns();
  const [totals, setTotals] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const documents = quotation && useDocuments(quotation, t);

  const getQuotation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quotations/show/${id}`
      });
      setQuotation(data);
      setTotals([
        {
          total_type: t('quotations.table.base_total'),
          discount: `${data.summary.price_conditions.discount.number} ${
            data.summary.price_conditions.discount.number === 'monetary'
              ? '€'
              : '%'
          }`,
          VAT: data.global_VAT_rate,
          ...data.summary.base_total
        },
        {
          total_type: t('quotations.table.option_total'),
          discount: data.summary.price_conditions.discount,
          VAT: data.global_VAT_rate,
          ...data.summary.option_total
        }
      ]);
    } catch (e) {
      message(e);
    }
  };

  const getKanbanStatuses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'kanbanview-quotes'
      });
      setKanbanStatuses(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getKanbanStatuses();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getQuotation();
    })();
  }, [forceRefresh]);

  const patchQuotationStatus = async (kanbanStatus) => {
    let body;
    const status = kanbanStatuses.find(
      (statusItem) => statusItem.title === kanbanStatus
    );
    if (status) {
      body = {
        status: 'KANBAN_STATUS',
        kanban_status: status._id
      };
    } else {
      body = {
        status: kanbanStatus,
        kanban_status: null
      };
    }

    try {
      await dispatchAPI('PATCH', {
        url: `quotations/status/${id}`,
        body
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.data.message);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('quotations.show.details', { quotation })}
        extra={
          <ExtraButtons
            quotation={quotation}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
            kanbanStatuses={kanbanStatuses}
            patchQuotationStatus={patchQuotationStatus}
          />
        }
      />
      <ContentCustom>
        <QuotationsSteps
          kanbanStatuses={kanbanStatuses}
          quotation={quotation}
          patchQuotationStatus={patchQuotationStatus}
        />
        <Divider />
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <Card
              title={t('quotations.show.customer_infos')}
              style={{ marginBottom: 16, height: '100%' }}
            >
              <DescriptionList data={customerFields} />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={t('quotations.show.general_infos')}
              style={{ marginBottom: 16, height: '100%' }}
            >
              <DescriptionList data={generalInfosFields} />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Card title={t('quotations.show.totals')}>
              <Table
                dataSource={totals}
                columns={totalColumns}
                scroll={{ x: 1000 }}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <FilesManagement
              quotation={quotation}
              documents={documents}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card>
              <QuotationDetailsTables quotation={quotation} />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

import { Form, Button, Tabs, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { VolumeItem } from './VolumeItem';

export const VolumeForm = ({
  finishingProducts,
  projectTypes,
  roomTypes,
  materialProvidedByCustomer,
  form
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('0');

  return (
    <Form.List name={['materials']}>
      {(fields, { add, remove }) => (
        <Tabs
          type="card"
          activeKey={activeTab}
          className="custom-tabs-volume-form"
          onChange={(activeKey) => {
            setActiveTab(activeKey);
          }}
          tabBarExtraContent={
            <Button
              type="dashed"
              onClick={() => {
                add();
                setActiveTab(`${fields.length}`);
              }}
              icon={<PlusOutlined />}
            >
              {t('quotations.form.materials.add_volume')}
            </Button>
          }
        >
          {fields.map(({ key, name, ...restField }) => (
            <Tabs.TabPane
              tab={
                <Row>
                  <Col>{`${t('quotations.form.materials.volumes')} ${
                    name + 1
                  }`}</Col>
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        remove(name);
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </Col>
                </Row>
              }
              key={key}
            >
              <VolumeItem
                key={key}
                name={name}
                materialProvidedByCustomer={materialProvidedByCustomer}
                restField={restField}
                finishingProducts={finishingProducts}
                projectTypes={projectTypes}
                roomTypes={roomTypes}
                form={form}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </Form.List>
  );
};

VolumeForm.propTypes = {
  finishingProducts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  projectTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      denomination: PropTypes.string
    })
  ),
  materialProvidedByCustomer: PropTypes.bool,
  form: PropTypes.shape({})
};

VolumeForm.defaultProps = {
  finishingProducts: null,
  projectTypes: null,
  roomTypes: null,
  materialProvidedByCustomer: undefined,
  form: null
};
